import React, { useEffect, useState } from "react"
import logo from '../../assets/images/logo_oni.jpg'
import { Link } from "react-router-dom";
const Header = () => {
    return (
        <>
            {/* <div id="preloader">
                <div id="status">&nbsp;</div>
            </div> */}

            <section>
                <div class="container-fluid land-full land-3-top-full">
                    <div class="container land-container">
                        <div class="row land-3-top">
                            <div class="col-md-6 land-3-top-logo">
                                <Link to={"/"}><img src={logo} /></Link>
                            </div>
                            <div class="col-md-6 land-3-top-contact">
                                <ul>
                                    <li>  25 49 77 95</li>
                                    <li>oni@oni.bf</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Header