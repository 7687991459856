import React, { useEffect } from "react";
import constant from "../utility/constant";
const LoginPage = React.lazy(() => import("../pages/common"));
const Renouvellement = React.lazy(() => import("../pages/private/backoffice/renouvellement"));
const OniLogin = React.lazy(() => import("../pages/common/oni"));
const Demande = React.lazy(() => import("../pages/common/demande"));
const OniDemande = React.lazy(() => import("../pages/admin/demande"));
const Suivre = React.lazy(() => import("../pages/common/suivre"));
const Dashboard = React.lazy(() => import("../pages/private/backoffice"));
const DashboardOni = React.lazy(() => import("../pages/admin"));
const UserForm = React.lazy(() => import("../pages/private/backoffice/form/UserForm"));
const Compte = React.lazy(() => import("../pages/private/backoffice/comptes"));
const Examen = React.lazy(() => import("../pages/private/backoffice/demande"));
const Accepter = React.lazy(() => import("../pages/private/backoffice/demande/valide"));
const Rejeter = React.lazy(() => import("../pages/private/backoffice/demande/rejet"));
const DemandeSow = React.lazy(() => import("../pages/private/backoffice/demande/show"));
const Password = React.lazy(() => import("../pages/private/backoffice/comptes/password"));
const MotPasse = React.lazy(() => import("../pages/common/password"));
const RenouvellementDemande = React.lazy(() => import("../pages/private/backoffice/demande/renouvellement"));

const appConstant = { ...constant };
const role = { ...appConstant?.appRole };

const privateRoutes =[
   
    {
        path: "/dashboard",
        component: Dashboard,
        permissions:["all"],
    },
    {
        path: "/dashboard",
        component: Dashboard,
        permissions:[role?.sousUser],
    },
   
    {
        path: "/comptes",
        component: Compte,
        permissions:[role?.manager],
    },
   
    {
        path: "/compte/create",
        component: UserForm,
        permissions:[role?.manager],
    },
    {
        path: "/accreditation",
        component: Renouvellement,
        permissions:[role?.manager],
    },
    {
        path: "/demandes/recues",
        component: Examen,
        permissions:[role?.cil],
    },
    {
        path: "/demandes/accptées",
        component: Accepter,
        permissions:[role?.cil],
    },
    {
        path: "/demandes/rejetees",
        component: Rejeter,
        permissions:[role?.cil],
    },
    {
        path: "/demandes/renouvellement",
        component: RenouvellementDemande,
        permissions:[role?.cil],
    },
    {
        path: "/demande/show",
        component: DemandeSow,
        permissions:[role?.cil],
    },
    {
        path: "/compte/mot-de-passe",
        component: Password,
        permissions:["all"],
    },
   
];


const publicRoutes =[
  
    // {
    //     path: "/login",
    //     component: LoginPage,
    //     permissions:["all"],
    // },
    {
        path: "/faire-demande",
        component: Demande,
        permissions:["all"],
    },
    {
        path: "/suivre",
        component: Suivre,
        permissions:["all"],
    },
    {
        path: "/password",
        component: MotPasse,
        permissions:["all"],
    }
];

export { publicRoutes, privateRoutes };
