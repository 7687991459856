import React, { useState } from 'react';
import { Button, Input, Modal, ModalBody, Spinner } from 'reactstrap';
function PrivilegeModal({ modal, toggle, categorie,handleAddP, formValues, setFormValues }) {
   
  const [loading, setLoading] = useState(false);
  const [validationMessages, setValidationMessages] = useState({});
    const [selectedPrivileges, setSelectedPrivileges] = useState([]);
    const handlePrivilegeChange = (privilegeId) => {
        if (selectedPrivileges.includes(privilegeId)) {
          setSelectedPrivileges(selectedPrivileges.filter(id => id !== privilegeId));
          // Supprimer les valeurs du formulaire pour ce privilège
          setFormValues(prevState => ({
            ...prevState,
            addDemandePrivilegeDtos: prevState.addDemandePrivilegeDtos.filter(item => item.privilegeId !== privilegeId)
          }));
        } else {
          setSelectedPrivileges([...selectedPrivileges, privilegeId]);
          // Initialiser les valeurs du formulaire pour ce privilège
          setFormValues(prevState => ({
            ...prevState,
            addDemandePrivilegeDtos: [
              ...prevState.addDemandePrivilegeDtos,
              {
                privilegeId,
                accesType: '',
                validity: ''
              }
            ]
          }));
        }
      };
    
      const handleValidityChange = (privilegeId, validity) => {
        setFormValues(prevState => ({
          ...prevState,
          addDemandePrivilegeDtos: prevState.addDemandePrivilegeDtos.map(item =>
            item.privilegeId === privilegeId ? { ...item, validity } : item
          )
        }));
      };
    
      const handleaccesTypeChange = (privilegeId, accesType) => {
        setFormValues(prevState => ({
          ...prevState,
          addDemandePrivilegeDtos: prevState.addDemandePrivilegeDtos.map(item =>
            item.privilegeId === privilegeId ? { ...item, accesType } : item
          )
        }));
      };
    
      const validateForm = () => {
        let isValid = true;
        let messages = {};

        formValues.addDemandePrivilegeDtos.forEach(privilege => {
            if (!privilege.validity) {
                isValid = false;
                messages[privilege.privilegeId] = 'Veuillez choisir la validité en mois.';
            } else if (!privilege.accesType) {
                isValid = false;
                messages[privilege.privilegeId] = "Veuillez choisir le type d'accès.";
            }
        });

        setValidationMessages(messages);
        return isValid;
    };

    const handleAddPWithValidation = () => {
        if (validateForm()) {
            handleAddP();
        }
    };
    return (
        <div>

            <Modal isOpen={modal} toggle={toggle} >
                {/* <ModalHeader>Modal title</ModalHeader> */}
                <ModalBody>
                    <div class="modal-body text-center p-5">
                        {/* <lord-icon src="https://cdn.lordicon.com/hrqwmuhr.json" trigger="loop" colors="primary:#121331,secondary:#08a88a"></lord-icon> */}
                        <div class="mt-4">
                            <div className="text-muted">

                                <h6 className="mb-3 fw-semibold text-uppercase">Ajoutez d'autres privilèges</h6>
                                <ul className="ps-3 list-unstyled vstack gap-2">
                                    {categorie?.privileges?.map((privilege, index) => (
                                        <li key={index}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id={`productTask${index}`}
                                                    checked={selectedPrivileges.includes(privilege.id)}
                                                    onChange={() => handlePrivilegeChange(privilege.id)}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlhtmlFor={`productTask${index}`}
                                                >
                                                    {`${privilege?.name}`}
                                                </label>
                                            </div>
                                            {selectedPrivileges.includes(privilege.id) && (
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                        <label>
                                                            Durée de validité en mois
                                                            <span className='text-danger'> *</span>
                                                        </label>
                                                        <select
                                                            className="form-select mb-3"
                                                            aria-label="Default select example"
                                                            value={formValues.addDemandePrivilegeDtos.find(item => item.privilegeId === privilege.id)?.validity || ''}
                                                            onChange={(e) => handleValidityChange(privilege.id, e.target.value)}
                                                        >
                                                            <option>Choisir la validité en mois</option>
                                                            {[...Array(24).keys()].map(month => (
                                                                <option key={month} value={month + 1}>{month + 1}</option>
                                                            ))}
                                                        </select>
                                                        {validationMessages[privilege.id] && !formValues.addDemandePrivilegeDtos.find(item => item.privilegeId === privilege.id)?.validity && (
                                                            <span className='text-danger'>{validationMessages[privilege.id]}</span>
                                                        )}
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label>
                                                            Type d'accès
                                                            <span className='text-danger'> *</span>
                                                        </label>
                                                        <select
                                                            className="form-select mb-3"
                                                            aria-label="Default select example"
                                                            value={formValues.addDemandePrivilegeDtos.find(item => item.privilegeId === privilege.id)?.accesType || ''}
                                                            onChange={(e) => handleaccesTypeChange(privilege.id, e.target.value)}
                                                        >
                                                            <option>Type d'accès</option>
                                                            <option value="ACCES_API">Accès Api</option>
                                                            <option value="ACCES_PLATEFORME">Accès application</option>
                                                        </select>
                                                        {validationMessages[privilege.id] && !formValues.addDemandePrivilegeDtos.find(item => item.privilegeId === privilege.id)?.accesType && (
                                                            <span className='text-danger'>{validationMessages[privilege.id]}</span>
                                                        )}
                                                    </div>


                                                </div>
                                            )}
                                        </li>
                                    ))}

                                </ul>

                                <div className='text-center mt-3'>
                                    {loading ? (
                                        <Button color="success" className="btn-load">
                                            <span className="d-flex align-items-center">
                                                <Spinner size="sm" className="flex-shrink-0"> En cours... </Spinner>
                                                <span className="flex-grow-1 ms-2">
                                                    En cours...
                                                </span>
                                            </span>
                                        </Button>
                                    ) : (

                                        <>
                                        <Button onClick={toggle} className='mx-2'>Fermer</Button>
                                        <Button className='btn btn-success' onClick={handleAddPWithValidation}>Ajouter</Button>
                                        
                                        </>
                                    )}

                                </div>
                            </div>
                            
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default PrivilegeModal;