import React, { useEffect, useState } from "react"
import footerimg from "../../../src/assets/armoirie.png"
const Footer = () => {

    return (
        <>
            <div class="mx-4">
                <div class="row mt-5">
                    <div class="col-md-2 foot-logo">
                        <img src={footerimg} alt="logo"/>
                    <h4 className="my-2">Burkina Faso</h4>

                    </div>
                    <div class="col-md-4">
                    <h4>Documents Utiles</h4>
                    <ul>
                            <li className="d-flex"><i className="  ri-download-line me-4"></i><a target="_blank" href="/demande/decision_2022-018_fixation_cout_acces_base_de_donnees_ONI.pdf">Decision 2022-018 fixation cout acces base de donnees ONI</a> </li>
                            <li className="d-flex"><i className="  ri-download-line me-4"></i><a target="_blank" href="/demande/decision_2022-036_composition_dossier_de_demande_dacces_base_de_donnees_ONI.pdf">Decision 2022-036 composition dossier de demande d'acces base de donnees ONI</a> </li>
                            <li className="d-flex"><i className="  ri-download-line me-4"></i><a target="_blank" href="/demande/proposition_de_demande_d_accreditation.docx">Demande d'accréditation</a> </li>
                           
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <h4>Lien utiles</h4>
                        <ul>
                            <li><a href="https://oni.bf/nos-prestations/cnib/">CNIB</a> </li>
                            <li><a href="https://oni.bf/nos-prestations/passeport/">PASSEPORT</a> </li>
                            <li><a href="https://oni.bf/nos-prestations/documents-specifiques/">DOCUMENTS SPECIFIQUES</a> </li>
                           
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <h4>Contact</h4>
                        <ul>
                       <li>Tel : 25 49 77 95</li>
                       <li>Email : <a href="mailTo:oni@oni.gov.bf">oni@oni.gov.bf</a></li>
                       <li>Adresse : 8G84+M9J, Ouaga 2000, Ouagadougou</li>
                       <li>Site web: <a target="_blank" href="https://oni.bf">www.oni.bf</a></li>
                        </ul>
                    </div>
                    
                </div>
                <div class="row mt-2">
                    <div class="text-center">
                        <p>ONI - Copyrights © 2024  - Tous droits reservés.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer