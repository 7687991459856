import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'reactstrap';
import makeAnimated from "react-select/animated";
import { FormikWizard } from 'formik-wizard-form';
import PersonelInfo from './demande/personelInfo';
import Accreditation from './demande/accreditation';
import { Box, Button, Step, StepLabel, Stepper } from '@material-ui/core';
import * as Yup from "yup";
import { API_BASIC, API_BASIC_USER } from '../../utility/ApiService';
import ApiRoute from '../../utility/ApiRoute';
import Recapitulatif from './demande/recapitulatif';
import Payment from './demande/payment';
import DemandeSucces from '../../components/modal/demandeSuccess';
import { useNavigate } from 'react-router-dom';
import PrivilegeInfo from './demande/privilegeInfo';
import Cadre from './demande/cadre';
import moment from 'moment';

function Demande(props) {
    const selectedMulti3 = useRef([]);
    const navigate = useNavigate();

    const [finished, setFinished] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [config, setConfig] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);

    const animatedComponents = makeAnimated();
  

    useEffect(() => {
        (async () => {
            setLoading(true);
            await API_BASIC.get(ApiRoute.categories).then(response => {
                const { data } = response;
                setCategories(data);
                setLoading(false);
            });
        })();
    }, []);
    const getCategoryCodeById = (id) => {
        const category = categories.find(cat => cat.id === id);
     
        return category ? category.code : null;
    };
    useEffect(() => {
        (async () => {
            await API_BASIC_USER.get(ApiRoute.config).then(response => {
                const { data } = response;
                setConfig(data);
            });
        })();
    }, []);
    // console.log(config);
    const initialValues = {
        structure: '',
        phoneNumber: '',
        email: '',
        companySign: '',
        geographicalLocation: '',
        numberIFU: '',
        numberRCCM: '',
        personne: '',
        categorieId: '',
        categortyName: '',
        numeroQuitance: '',
        reasonRequest: '',
        montantPaiement: config ? config?.submissionFee:100000,
        datePaiement: '',
        fichiers: [],
        demandePrivilegeDTOS: [],
        requiredDocuments: [],
       
    }
    const phoneRegExp = /^(\+?[1-9]{1,4}[ \-]?)?(\([0-9]{2,3}\)[ \-]?)?([0-9]{3,4}[ \-]?)*[0-9]{3,4}?$/;

    const noSpecialCharsRegExp = /^[a-zA-ZÀ-ÿ0-9\s']*$/;
    const fileSizeValidation = (files) => {
        const maxSize = 5242880;
     
        for (let file of files) {
            if (file.size > maxSize) {
                return false;
            }
        }
        return true;
    };
    return (
        <div>
            <section>
                <div className="container-fluid land-full land-3-banner-full">
                    <div className="container land-container">
                        <div className="land-3-banners">
                            <h1>FORMULAIRE DE DEMANDE D’ACCREDITATION</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className='pt-5'>
                <div className="container">
                    <div className="row" id=''>
                        <div className='col-md-12 col-12'>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row gx-lg-5">
                                        <div className="col-xl-12 col-md-6 mx-auto">
                                            <FormikWizard
                                                validateOnChange={false}
                                                initialValues={initialValues}
                                                onSubmit={(values) => {
                                                    (async () => {
                                                        setLoading(true);
                                                        await API_BASIC.post(ApiRoute.postdemande, {
                                                            email: values.email,
                                                            personne: values.personne,
                                                            phoneNumber: values.phoneNumber,
                                                            companySign: values.companySign,
                                                            geographicalLocation: values.geographicalLocation,
                                                            numberRCCM: values.numberRCCM,
                                                            numberIFU: values.numberIFU,
                                                            structure: values.structure,
                                                            requiredDocuments: values.requiredDocuments,
                                                            categorieId: values.categorieId,
                                                            demandePrivilegeDTOS: values.demandePrivilegeDTOS,
                                                            numeroQuitance: values?.numeroQuitance,
                                                            montantPaiement: values?.montantPaiement,
                                                            datePaiement:moment(values?.datePaiement).format('YYYY-MM-DDTHH:mm:ss.SSSSSS') ,
                                                            fichiers: values?.fichiers
                                                        }).then(response => {
                                                            if (response.status) {
                                                                setLoading(false);
                                                                setShowSuccess(true);
                                                            } else {
                                                                setLoading(false);
                                                            }
                                                        }, error => {
                                                            setLoading(false);
                                                            console.error('error', error);
                                                        });
                                                    })();
                                                }}
                                                validateOnNext
                                                activeStepIndex={0}
                                                steps={[
                                                    {
                                                        component: Accreditation,
                                                        validationSchema: Yup.object().shape({
                                                            categorieId: Yup.string().required('Veuillez sélectionner une catégorie'),
                                                            demandePrivilegeDTOS: Yup.array().of(Yup.object().shape({
                                                                privilegeId: Yup.string().required("L'identifiant du privilège est requis"),
                                                                validity: Yup.number().min(1, "La validité doit être d'au moins 2 mois").required("La validité est requise"),
                                                                accesType: Yup.string().required("Le type d'accès est requis"),
                                                            })).min(1, "Selectionner au moins un privilège et renseigner la durée de validité et le type d'accès"),
                                                        })
                                                    },
                                                    {
                                                        component: Cadre,
                                                        validationSchema: Yup.object().shape({
                                                            reasonRequest: Yup.string().required('Le champ motif est obligatoire'),
                                                            // applicationName: Yup.string().required('Le champ nom des applications est obligatoire'),
                                                            // consumerModule: Yup.string().required('Le champ module est obligatoire'),
                                                            // requiredDocuments: Yup.array().of(Yup.object().shape({
                                                            //      })).min(1, "Selectionner au moins un privilège et renseigner la durée de validité et le type d'accès"),
                                                        })
                                                    },
                                                    {
                                                        component: PersonelInfo,
                                                        validationSchema: Yup.object().shape({
                                                            structure: Yup.string().matches(noSpecialCharsRegExp, 'Les caractères spéciaux ne sont pas autorisés').required("Le champ dénomination est obligatoire"),
                                                            phoneNumber: Yup.string().matches(phoneRegExp, 'Les caractères spéciaux ne sont pas autorisés').max(20, 'Le numéro de téléphone ne peut pas dépasser 20 caractères').required("Le champ téléphone est obligatoire"),
                                                            email: Yup.string().email('Email invalide').required("Le champ Email est obligatoire"),
                                                            companySign: Yup.string().matches(noSpecialCharsRegExp, 'Les caractères spéciaux ne sont pas autorisés').required("Le champ sigle est obligatoire"),
                                                            geographicalLocation: Yup.string().matches(noSpecialCharsRegExp, 'Les caractères spéciaux ne sont pas autorisés').required("Le champ localisation est obligatoire"),
                                                            numberIFU: Yup.string().matches(noSpecialCharsRegExp, 'Les caractères spéciaux ne sont pas autorisés').when('categorieId', {
                                                                is: value => {
                                                                    const categoryCode = getCategoryCodeById(value);
                                                                    return categoryCode !== 'A' && categoryCode !== 'B';
                                                                },
                                                                then: schema => schema.required("Le champ numéro IFU est obligatoire"),
                                                                otherwise: schema => schema.notRequired()
                                                            }),
                                                            numberRCCM: Yup.string().when('categorieId', {
                                                                is: value => {
                                                                    const categoryCode = getCategoryCodeById(value);
                                                                    return categoryCode !== 'A' && categoryCode !== 'B';
                                                                },
                                                                then: schema => schema.matches(noSpecialCharsRegExp, 'Les caractères spéciaux ne sont pas autorisés').required("Le champ numéro RCCM est obligatoire"),
                                                                otherwise: schema => schema.notRequired()
                                                            }),
                                                            personne: Yup.string().matches(noSpecialCharsRegExp, 'Les caractères spéciaux ne sont pas autorisés').required("Le champ personne responsable à contacter est obligatoire"),
                                                        })
                                                    },
                                                    {
                                                        component: PrivilegeInfo,
                                                        validationSchema: Yup.object().shape({
                                                            requiredDocuments: Yup.array().min(1, "Ce champ est requis").test('fileSize', 'Taille maximale de fichier dépassée (5 Mo)', value => fileSizeValidation(value)),
                                                        })
                                                    },
                                                    {
                                                        component: Payment,
                                                        validationSchema: Yup.object().shape({
                                                            numeroQuitance: Yup.string().matches(noSpecialCharsRegExp, 'Les caractères spéciaux ne sont pas autorisés').required('Le champ numéro de quittance est obligatoire'),
                                                            montantPaiement: Yup.string().matches(noSpecialCharsRegExp, 'Les caractères spéciaux ne sont pas autorisés').required('Le champ montant de paiement est obligatoire'),
                                                            datePaiement: Yup.string().required('Le champ date de paiement est obligatoire'),
                                                            fichiers: Yup.array().test('fileSize', 'Un fichier ne doit pas dépasser 5 Mo', value => fileSizeValidation(value))
                                                                                .min(1, "Le champ est quittance est requis"),
                                                        })
                                                    },
                                                    {
                                                        component: Recapitulatif,
                                                        validationSchema: Yup.object().shape({}),
                                                    }
                                                ]}
                                            >
                                                {({
                                                    currentStepIndex,
                                                    renderComponent,
                                                    handlePrev,
                                                    handleNext,
                                                    isNextDisabled,
                                                    isPrevDisabled,
                                                    isLastStep,
                                                }) => (
                                                    <>
                                                        <DemandeSucces modal={showSuccess} />
                                                        <Box sx={{ width: "100%", my: "1rem" }}>
                                                            <Stepper activeStep={currentStepIndex}>
                                                                <Step completed={currentStepIndex > 0}>
                                                                    <StepLabel>ACCREDITATION DEMANDEE</StepLabel>
                                                                </Step>
                                                                <Step completed={currentStepIndex > 1}>
                                                                    <StepLabel>CADRE D'UTILISATION</StepLabel>
                                                                </Step>
                                                                <Step completed={currentStepIndex > 2}>
                                                                    <StepLabel>IDENTIFICATION DE LA STRUCTURE</StepLabel>
                                                                </Step>
                                                                <Step completed={currentStepIndex > 3}>
                                                                    <StepLabel>DOCUMENTS</StepLabel>
                                                                </Step>
                                                                <Step completed={currentStepIndex > 4}>
                                                                    <StepLabel>PAIEMENT DES FRAIS</StepLabel>
                                                                </Step>
                                                                <Step completed={finished}>
                                                                    <StepLabel>RECAPITULATIF</StepLabel>
                                                                </Step>
                                                            </Stepper>
                                                        </Box>
                                                        <Box my="2rem">{renderComponent()}</Box>
                                                        <Box display="flex" justifyContent="space-between" className='mx-4'>
                                                            <Button
                                                                variant="contained"
                                                                disabled={isPrevDisabled}
                                                                type="secondary"
                                                                onClick={handlePrev}
                                                            >
                                                                <i className="ri-arrow-left-line label-icon align-middle fs-16 me-3"></i>
                                                                Precedent
                                                            </Button>
                                                            {(currentStepIndex === 5 && loading) ? (
                                                                <Button color="success" className="btn-load">
                                                                    <span className="d-flex align-items-centerx@">
                                                                        <Spinner size="sm" className="flex-shrink-0"> En cours... </Spinner>
                                                                        <span className="flex-grow-1 ms-2">En cours...</span>
                                                                    </span>
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    variant="contained"
                                                                    type="primary"
                                                                    onClick={handleNext}
                                                                    className='btn btn-danger'
                                                                >
                                                                    {currentStepIndex === 5 ? "Enregistrer" : "Suivant"}
                                                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                                </Button>
                                                            )}
                                                        </Box>
                                                        <div className="text-center pt-2">
                                                            <small className='etoile'>Les champs marqués d'une étoile (<span className='text-danger'> * </span>) sont obligatoires</small>
                                                        </div>
                                                    </>
                                                )}
                                            </FormikWizard>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Demande;
