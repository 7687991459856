import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './entete';
import Footer from './footer';

const CommonLayout = () => {
    return (
        <>
            <Header />
            <Outlet />
            <footer class="container-fluid land-full  footers pt-5">
                <Footer />
            </footer>
        </>
    );
}

export default CommonLayout;
