import React, { useEffect, useState } from 'react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { Input, Label, FormGroup, Col, Row, Spinner, Button, Modal, ModalBody } from 'reactstrap';
import { API_BASIC } from '../../utility/ApiService';
import ApiRoute from '../../utility/ApiRoute';
import moment from 'moment';
import authService from '../../services/authService';

function Accreditation({ data, modal, toggle }) {
    const [privileges, setPrivileges] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [privilegeStates, setPrivilegeStates] = useState([]);
    const [categorieId, setCategorieId] = useState(data?.categorieId || '');
    const [categorieold, setCategorieold] = useState(data?.categorieId);
    const [demandePrivilegeDTOS, setDemandePrivilegeDTOS] = useState(data?.privileges || []);
    const [demandePrivilegeDTOSOld, setDemandePrivilegeDTOSOld] = useState(data?.privileges || []);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState([]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const response = await API_BASIC.get(ApiRoute.categoriesall);
                const { data } = response;
                setCategories(data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (categorieId===categorieold) {
            const selectedCategoryData = categories.find(category => category.id === categorieId);
            const selectedCategoryPrivileges = selectedCategoryData?.privileges.map(privilege => ({
                value: privilege.id,
                description: privilege.description,
                label: privilege.name,
                categorie: selectedCategoryData.name,
                prix: privilege.prix
            })) || [];
            setPrivileges(selectedCategoryPrivileges);
            setDemandePrivilegeDTOS(data?.privileges)

        }else{
            setDemandePrivilegeDTOS([])
        }
    }, [categories, categorieId]);
    useEffect(() => {
        setPrivilegeStates(privileges.map(p => ({
            id: p.value,
            isActive: demandePrivilegeDTOS.some(d => d.privilegeId === p.value),
            categorie: p.categorie,
            prix: p.prix,
            validity: demandePrivilegeDTOS.find(f => f.privilegeId === p.value)?.validity || 0,
        })));
        setForm(demandePrivilegeDTOS);

    }, [privileges, demandePrivilegeDTOS]);
    const validateForm = () => {
        let formErrors = {};
        if (!categorieId) formErrors.categorieId = "Catégorie est obligatoire";
        demandePrivilegeDTOS.forEach(privilege => {
            if (!privilege.validity) formErrors.validity = "Durée de validité est obligatoire";
            if (!privilege.accessType) formErrors.accessType = "Type d'accès est obligatoire";
        });
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const onSelect = (e) => {
        setCategorieId(e.target.value);
        
    };

    const handlePrivilegeChange = (index) => {
        const newPrivilegeStates = [...privilegeStates];
        let privilege = newPrivilegeStates.find((p) => p.id === index);
        privilege.isActive = !privilege.isActive;
        setPrivilegeStates(newPrivilegeStates);
        if (privilege.isActive) {
            setDemandePrivilegeDTOS(prev => [...prev, { privilegeId: index, validity: 1, accessType: "" }]);
        } else {
            setDemandePrivilegeDTOS(prev => prev.filter(p => p.privilegeId !== index));
        }
    };

    const handleValue = ({ type, value, index }) => {
        let newForm = demandePrivilegeDTOS.map(f => {
            if (f.privilegeId === index) {
                return { ...f, [type]: value };
            }
            return f;
        });
        setDemandePrivilegeDTOS(newForm);
        // checkPrivilegeExpiration(newForm);
    };

    const handleAccessTypeChange = (event, index) => {
        const value = event.target.value;
        handleValue({ type: "accessType", value, index });
    };

    const handleValidityChange = (event, index) => {
        const value = event.target.value;
        handleValue({ type: "validity", value: parseInt(value), index });
    };

 

    const handleSubmit = (e) => {
       
        e.preventDefault();
        if (validateForm()) {
            authService.editDemande(categorieId, demandePrivilegeDTOS, data).then(
                (resData) => {
                    //  setDemandes(resData?.data)
                    setLoading(false)
                }
            );
        }
    };

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Type d'accès</Popover.Header>
            <Popover.Body>
                <p>Accès API : l'accès API vous donne accès à la base de données de l'ONI via API</p>
                <p>Accès Plateforme : l'accès Plateforme vous donne accès à la base de données de l'ONI via la plateforme de l'ONI</p>
            </Popover.Body>
        </Popover>
    );

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} className='modal-dialog modal-lg'>
                <ModalBody>
                    {loading ? (
                        <div className='text-center'>
                            <Spinner size="sm" className="flex-shrink-0">En cours...</Spinner>
                        </div>
                    ) : (
                        <div className="row gx-lg-5">
                            
                            <div className="col-12"></div>
                            <div className="col-xl-12 col-md-6 mx-auto">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className='mx-4'>
                                                <FormGroup>
                                                    <Label htmlFor="categorieSelect">
                                                        Choisir la catégorie de votre structure
                                                        <span className='text-danger'> *</span>
                                                    </Label>
                                                    <Form.Select required value={categorieId} onChange={onSelect}>
                                                        <option value="">Choisir la catégorie de votre structure</option>
                                                        {categories.map((data, key) => (
                                                            <option key={key} value={data.id}>{data.name} ({data.description})</option>
                                                        ))}
                                                    </Form.Select>
                                                    {errors.categorieId && <div className="text-danger">{errors.categorieId}</div>}
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="col-12 my-3">
                                            <div className='row prive mx-4'>
                                                {categorieId && (
                                                    <Label htmlFor="privilegesSelect">
                                                        Choisir vos privilèges souhaités
                                                        <span className='text-danger'> *</span>
                                                    </Label>
                                                )}
                                                {privileges.map((data, key) => {
                                                    const isCategorieD = categories.find(category => category.id === categorieId)?.code === 'D';
                                                    const categorieD = categories.filter(category => category.id === categorieId);
                                                    return (
                                                        <React.Fragment key={key}>
                                                            <div className="form-check form-check-warning px-3 d-flex py-2">
                                                                <div>
                                                                    <Label className="form-check-label">
                                                                        {data.label}
                                                                    </Label>
                                                                    <p>{data.description}</p>
                                                                </div>
                                                                <div className='privechec'>
                                                                    <FormGroup switch className="form-check form-switch form-switch-success">
                                                                        <Input type="switch" className="form-check form-switch form-switch-success" role="switch" onChange={() => handlePrivilegeChange(data.value)}
                                                                            checked={isCategorieD ? true : privilegeStates.find((p) => p.id === data.value)?.isActive}
                                                                        />
                                                                    </FormGroup>
                                                                    {isCategorieD ? (
                                                                        <span className='text-danger'>Tous les privilèges font {categorieD[0]?.fraisExceptionnel} F CFA</span>
                                                                    ) : (
                                                                        <span className='red-text'>Prix : {data.prix * (form.find((f) => f.privilegeId === data.value)?.validity || 1)} F CFA</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {privilegeStates.find((p) => p.id === data.value)?.isActive && (
                                                                <Row className='py-3'>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label htmlFor={`validity${data.value}`}>
                                                                                Durée de validité en mois
                                                                                <span className='text-danger'> *</span>
                                                                            </Label>
                                                                            <select className="form-select mb-3" aria-label="Default select example"
                                                                                id={`validity${data.value}`}
                                                                                value={demandePrivilegeDTOS.find((f) => f.privilegeId === data.value)?.validity || ''}
                                                                                onChange={(e) => handleValidityChange(e, data.value)}>
                                                                                <option value=''>Choisir la durée de validité en mois</option>
                                                                                {[...Array(24).keys()].map(i => (
                                                                                    <option key={i} value={i + 1}>{i + 1}</option>
                                                                                ))}
                                                                            </select>
                                                                            {errors.validity && <div className="text-danger">{errors.validity}</div>}
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label htmlFor={`accessType${data.value}`} className='typeacc'>
                                                                                Type d'accès
                                                                                <span className='text-danger'> *</span>
                                                                                <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                                                                    <i className='ri-information-line mx-2'></i>
                                                                                </OverlayTrigger>
                                                                            </Label>
                                                                            <select className="form-select mb-3" aria-label="Default select example"
                                                                                id={`accessType${data.value}`}
                                                                                value={demandePrivilegeDTOS.find((f) => f.privilegeId === data.value)?.accessType || ''}
                                                                                onChange={(e) => handleAccessTypeChange(e, data.value)}>
                                                                                <option value=''>Choisir le type d'accès</option>
                                                                                <option value="ACCES_API">Accès API</option>
                                                                                <option value="ACCES_PLATEFORME">Accès Plateforme</option>
                                                                            </select>
                                                                            {errors.accessType && <div className="text-danger">{errors.accessType}</div>}
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-12 justify-content-center d-flex">
                                            <Button color="secondary" onClick={toggle}>
                                                Fermer
                                            </Button>
                                            <button type="button" className="btn btn-primary mx-2" onClick={handleSubmit}>
                                                Soumettre
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
}

export default Accreditation;
