import React from "react";
import ApiRoute from "../utility/ApiRoute";
import { API_BASIC, API_BASIC_CONSULTATION, API_BASIC_PASSWORD, API_BASIC_USER } from "../utility/ApiService";
import { delUserData, saveUserData, saveUserToken } from "../utility/Utils";
import { useNavigate } from "react-router";
import { jwtDecode } from "jwt-decode";
import moment from "moment";

class AuthService {


    async login(username, password) {
        try {
            const res = await API_BASIC_USER.post(ApiRoute.login, {
                username,
                password,
            })
            // console.log('log',res);
            // return res
            if (res.status === 200) {
              return { data: res, statut: true }
            } else {
              return { data: { errors: res?.data?.message }, statut: false }
            }
        
          } catch (error) {
            return { data: { errors: "Désolé, nous rencontrons un probléme de connexion. Vérifiez votre connexion internet et si le problème persite contactez le support." }, statut: false }
          }
       
    }
    async postAccount(data) {
        const response = await API_BASIC_USER.post(ApiRoute.postSubcount, {
            subAccountType: data?.subAccountType,
            service:data?.service,
            email: data?.email,
            privilegeId:data?.privilegeId,
            parentId: data?.parentId,
            type: data?.type,
            domainName:data?.domaine
        }).then((response) => {
            return response;
        });
        return response
    }
    async getuserById(id) {
        const response = await API_BASIC_USER.get(ApiRoute.usersbyid + '?id=' + id).then((response) => {
            return response;
        });
        return response
    }
    async getdemandeById(id) {
        const response = await API_BASIC.get(ApiRoute.demandeId + '/' + id).then((response) => {
            return response;
        });
        return response
    }
    async getprivilegeid(id) {
        const response = await API_BASIC.get(ApiRoute.privileges + '/' + id).then((response) => {
            return response;
        });
        return response
    }
    async getuser(id) {
        const response = await API_BASIC_USER.get(ApiRoute.users + '?parentId=' + id).then((response) => {
            return response;
        });
        return response
    }
    async getIdentity(data) {
        const response = await API_BASIC_CONSULTATION.post(ApiRoute.identity, {
            firstName:data?.firstName,
            name:data?.name,
            dateOfBirth:moment(data?.dateOfBirth).format('DD-MM-YYYY'),
            cardNumber:data?.cardNumber
        }).then((response) => {
            // console.log('response',response);
            return response;
        }).catch((error)=>{
            console.log('error', error);
        });
        return response
    }
    async rejetdemande(data, form) {
        const response = await API_BASIC.post(ApiRoute.avisCil, {
            demandId: data?.id,
            notice:'DEFAVORABLE',
            requiredDocuments:form
        });
        return response;
    }
    async activeUser(data) {
        const response = await API_BASIC_USER.put(ApiRoute.userenable + '?id=' + data?.id);
        return response;
    }
    async PostRenouvellement(id, data, files, demande) {
           
        const response = await API_BASIC.post(ApiRoute.renouvellementpost+'/'+demande?.id, {
            idCompteGestionnaire:id,
            renouvelerDemandePrivilegeDtos:data?.renouvelerDemandePrivilegeDtos,
            requiredDocuments:files
        }).then((response) => {
            return response;
        });
        return response
    }
    async resendcode(email) {
        try {
            const res = await API_BASIC_USER.put(ApiRoute.resendcode + "?email=" + email)
            if (res.status == 200) {
               
              return { data: res, statut: true }
            } else {
        
              return { data: { errors: res?.data?.message }, statut: false }
            }
        
          } catch (error) {
            return { data: { errors: "Désolé, nous rencontrons un probléme de connexion. Vérifiez votre connexion internet et si le problème persite contactez le support." }, statut: false }
          }
       
    }
    async code(email, code) {
        try {
            const res = await API_BASIC_USER.post(ApiRoute.code + "?email=" + email + "&code=" + code, {
                email,
                code
            })
           

            if (res.status == 200) {
                const token = res?.data?.data?.accessToken
                const user = jwtDecode(token);
                const data = { user: user, access_token: token }
                saveUserData(JSON.stringify(data));
              return { data: res, statut: true }
            } else {
        
              return { data: { errors: res?.data?.message }, statut: false }
            }
        
          } catch (error) {
            return { data: { errors: "Désolé, nous rencontrons un probléme de connexion. Vérifiez votre connexion internet et si le problème persite contactez le support." }, statut: false }
          }
       
    }
    async getDemandeById(id) {
        try {
            const response = await API_BASIC.get(ApiRoute.demandebyId + '/' + id);
            return response;
        } catch (error) {
            throw error;
        }
    }
    async ChangePassword(data) {
       
        try {
            const response = await API_BASIC_PASSWORD.put(ApiRoute.changePasse + '?id=' + data?.id, {
                currentPassword:data?.oldpasswordInput,
	            newPassword:data?.newpasswordInput
            });
            return response;
        } catch (error) {
            
            throw error;
        }
    }
    async getdemandesRecue(data) {
        
        try {
            const response = await API_BASIC.get(ApiRoute.alldemandeattente+'/'+data);
            return response;
        } catch (error) {
            
            throw error;
        }
    }
    async getdemandesCill(data) {
        
        try {
            const response = await API_BASIC.get(ApiRoute.demandeavisCil+'/'+data);
            return response;
        } catch (error) {
            
            throw error;
        }
    }
    async valideDemande(data, formFile) {
        const response = await API_BASIC.post(ApiRoute.validedemande, {
            demandId: data?.id,
            notice:'FAVORABLE',
            requiredDocuments:formFile
        });
        const data_1 = response;
        
        return data_1;
    }
    async avisCilDemande(data, formFile) {
        const response = await API_BASIC.post(ApiRoute.avisCil, {
            demandId: data?.id,
            notice:'FAVORABLE',
            requiredDocuments:formFile
        });
        const data_1 = response;
       
        return data_1;
    }
    async getCategorieById(id) {
        
        const response = await API_BASIC.get(ApiRoute.categories+'/'+id);
        const data = response;
        return data;
    }
    async getdemandesRenouvellement() {
        
        const response = await API_BASIC.get(ApiRoute.demanderenouvelle);
        const data = response;
        return data;
    }
    async editDemande(categorie,forminputs,demand) {
        const response = await API_BASIC.put(ApiRoute.editdemande+'/'+demand?.id,{
            demandePrivilegeDTOS:forminputs ,
            categorieId:categorie
        });
        const data = response;
        return data;
    }
    async getStatatistique() {
        const response = await API_BASIC_USER.get(ApiRoute.statistiqueAdmin);
        const data = response;
        return data;
    }
    async getStatatistiqueUser(id) {
        const response = await API_BASIC_USER.get(ApiRoute.statistiqueUser+'?userId='+id);
        const data = response;
        return data;
    }
    updateProfil(data) {
        return API_BASIC.patch(ApiRoute.userProfil + "/" + data.id, {
            id: data.id,
            nom: data.nom,
            email: data.email,
            telephone: data.telephone,
        }).then((response) => {
            const data = response?.data.data;

            return data;
        });
    }

    async logout(data) {
        try {
            const response = await API_BASIC_USER.post(ApiRoute.logout + '?username=' + data);
            // return response;
           
            if (response?.status===200) {
                delUserData();
                window.location.reload();
                window.location.href = '/';

            }
        } catch (error) {
             throw error;
        }

    }
}

export default new AuthService();
