import axios from "axios";
import Constant from "./constant";
import { delUserData, getUserData, isUserLoggedIn } from "./Utils";
// import {basicNotif} from '@shared/notification';

const appConstant = { ...Constant }

export const API_BASIC = axios.create({
    baseURL: `${appConstant.url}`
});
export const API_BASIC_USER = axios.create({
    baseURL: `${appConstant.url_user}`
});
export const API_BASIC_CONSULTATION = axios.create({
    baseURL: `${appConstant.url_consultation}`
});
export const API_BASIC_PASSWORD = axios.create({
    baseURL: `${appConstant.url_password}`
});
export const API_BASIC_folder = axios.create({
    baseURL: `${appConstant.mediaUrl}`
});

API_BASIC.interceptors.request.use(
    config => {
        if (isUserLoggedIn()) {
            const tokenBearer = getUserData().access_token
           

            config.headers.authorization = `${appConstant.tokenType} ${tokenBearer}`
            // if (config.method === 'post' || config.method === 'put' || config.method === 'patch') {
            //     config.headers['Content-Type'] = 'multipart/form-data';
            // }
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    });
    API_BASIC_PASSWORD.interceptors.request.use(
    config => {
        if (isUserLoggedIn()) {
            const tokenBearer = getUserData().access_token

            config.headers.authorization = `${appConstant.tokenType} ${tokenBearer}`
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    });
API_BASIC_USER.interceptors.request.use(
    config => {
        if (isUserLoggedIn()) {
            const tokenBearer = getUserData().access_token

            config.headers.authorization = tokenBearer ? `${appConstant.tokenType} ${tokenBearer}` : 'noAuth'
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    });
API_BASIC_CONSULTATION.interceptors.request.use(
    config => {
        if (isUserLoggedIn()) {
            const tokenBearer = getUserData().access_token
            config.headers.authorization = tokenBearer ? `${appConstant.tokenType} ${tokenBearer}` : 'noAuth'
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    });


API_BASIC.interceptors.response.use(
    response => response,
    error => {
        const { config, response } = error

        if (response && response.status === 401) {
            if (window.location?.pathname !== '/' && isUserLoggedIn()) {
                alert("Session expirée, veuillez vous reconnecter ")
                delUserData();
                window.location.replace('/')
            }
        }
        return Promise.reject(error);
    })
API_BASIC_USER.interceptors.response.use(
    response => {
        
        return response

    },
    error => {

        const { config, response } = error
        if (response && response.status === 401) {
            if (window.location?.pathname !== '/' && isUserLoggedIn()) {
                alert("Session expirée, veuillez vous reconnecter ")
                delUserData();
                window.location.replace('/')
            }
        }
        return Promise.reject(error);
    })
API_BASIC_CONSULTATION.interceptors.response.use(
    response => {

        return response
    },
    error => {


        const { config, response } = error

        if (response && response.status === 401) {
            if (window.location?.pathname !== '/' && isUserLoggedIn()) {
                alert("Session expirée, veuillez vous reconnecter ")
                delUserData();
                window.location.replace('/')
            }
        }
        
        
        return Promise.reject(error);
    })

export default { API_BASIC, API_BASIC_USER, API_BASIC_CONSULTATION };