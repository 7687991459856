import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Col, Input, Label, Row, FormGroup } from 'reactstrap';

function PersonelInfo({ errors, values, handleChange }) {
   
    return (
        <div className='mx-4'>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label htmlFor ="denomination">
                        {(values?.categortyName !== 'A' && values?.categortyName !== 'B')?('Dénomination de la société '):('Dénomination de la structure ')}
                            
                            <span className='text-danger'> *</span>
                        </Label>
                        <Input
                            required
                            type="text"
                            id="denomination"
                            value={values.structure}
                            name="structure"
                            onChange={handleChange}
                            placeholder="Dénomination de la structure"
                        />
                        {errors.structure && (
                            <p className="text-danger">{errors.structure}</p>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor ="localisation">
                            Localisation géographique
                            <span className='text-danger'> *</span>
                        </Label>
                        <Input
                            id="localisation"
                            name="geographicalLocation"
                            required
                            value={values.geographicalLocation}
                            onChange={handleChange}
                            placeholder="(Ex : Ouagadougou : Wemtenga, secteur …, en face de la Station …)"
                             type="textarea"
                        />
                        {errors.geographicalLocation && (
                            <p className="text-danger">{errors.geographicalLocation}</p>
                        )}
                    </FormGroup>
                    {(values?.categortyName !== 'A' && values?.categortyName !== 'B') && (

                        <FormGroup>
                            <Label htmlFor ="rccm">
                                N° RCCM
                                <span className='text-danger'> *</span>
                            </Label>
                            <Input
                                id="rccm"
                                name="numberRCCM"
                                required
                                value={values.numberRCCM}
                                onChange={handleChange}
                                placeholder="N° RCCM"
                                type="text"
                            />
                            {errors.numberRCCM && (
                                <p className="text-danger">{errors.numberRCCM}</p>
                            )}
                        </FormGroup>
                    )}
                        <FormGroup>
                        <Label htmlFor ="prenompersonne">
                            Nom et prénom de la personne responsable à contacter <span className='text-danger'> *</span>
                        </Label>
                        <Input
                            id="personne"
                            name="personne"
                            value={values.personne}
                            required
                            onChange={handleChange}
                            placeholder=" Nom et prénom de la personne responsable à contacter"
                            type="text"
                        />
                        {errors.personne && (
                            <p className="text-danger">{errors.personne}</p>
                        )}
                    </FormGroup>
                   

                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label htmlFor ="sigle">
                           {(values?.categortyName !== 'A' && values?.categortyName !== 'B')?('Sigle de la société'):('Sigle de la structure')}  <span className='text-danger'> *</span>
                        </Label>
                        <Input
                            id="sigle"
                            name="companySign"
                            value={values.companySign}
                            required
                            onChange={handleChange}
                            placeholder="Sigle de la structure"
                            type="text"
                        />
                        {errors.companySign && (
                            <p className="text-danger">{errors.companySign}</p>
                        )}
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor ="email">
                            Adresse mail <span className='text-danger'> *</span>
                        </Label>
                        <Input
                            id="email"
                            name="email"
                            value={values.email}
                            required
                            onChange={handleChange}
                            placeholder="Adresse mail"
                            type="text"
                        />
                        {errors.email && (
                            <p className="text-danger">{errors.email}</p>
                        )}
                    </FormGroup>
                    {(values?.categortyName !== 'A' && values?.categortyName !== 'B') && (
                        <FormGroup>
                            <Label htmlFor ="ifu">
                                N° IFU <span className='text-danger'> *</span>
                            </Label>
                            <Input
                                id="ifu"
                                name="numberIFU"
                                value={values.numberIFU}
                                required
                                onChange={handleChange}
                                placeholder="N° IFU"
                                type="text"
                            />
                            {errors.numberIFU && (
                                <p className="text-danger">{errors.numberIFU}</p>
                            )}
                        </FormGroup>
                    )}

                    
                    <FormGroup>
                        <Label htmlFor ="telephone">
                            Numéro de téléphone de la personne responsable à contacter
                            <span className='text-danger'> *</span>
                        </Label>
                        <Input
                            id="telephone"
                            name="phoneNumber"
                            required
                            value={values.phoneNumber}
                            onChange={handleChange}
                            placeholder="+226 00 00 00 00"
                            type="text"
                        />
                        {errors.phoneNumber && (
                            <p className="text-danger">{errors.phoneNumber}</p>
                        )}
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}

export default PersonelInfo;