import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "reactstrap";
import PrivilegeModal from "../../../components/modal/privileges";
import { API_BASIC } from "../../../utility/ApiService";
import ApiRoute from "../../../utility/ApiRoute";
import { TypeAcces } from "../../../utility/Utils";
import moment from "moment";

export default function Recapitulatif({ errors, values, handleChange }) {
  const [categorie, setCategorie] = React.useState([]);
  const [data, setData] = React.useState({});
  const [modal, setModal] = useState(false)
  let totalPrixTotal = 0;
  useEffect(() => {

    if (values) {
      setData(values);

    }
  }, [values]);

  const [formValues, setFormValues] = useState({
    addDemandePrivilegeDtos: [],

  });
  const handleAdd = () => {
    setModal(!modal)
  }
  useEffect(() => {
    (async () => {
      // setLoading(true)
      await API_BASIC.get(ApiRoute.categoriesall + '/' + data?.categorieId).then(response => {
        const { data } = response;
        setCategorie(data)
        // setLoading(false)
      }).catch((error) => {
        // setLoading(false)

      });
    })()
  }, [data])
  console.log(categorie);
  const handleAddP = () => {
    if (formValues?.addDemandePrivilegeDtos) {
      if (formValues?.addDemandePrivilegeDtos && Array.isArray(formValues.addDemandePrivilegeDtos)) {
        formValues.addDemandePrivilegeDtos.forEach((privilege, index) => {

          const privilegeExists = data?.demandePrivilegeDTOS.some(existingPrivilege => existingPrivilege.privilegeId === privilege.privilegeId);

          if (!privilegeExists) {
            const newPrivilege = { privilegeId: privilege?.privilegeId, validity: privilege?.validity, accesType: privilege?.accesType };
            data?.demandePrivilegeDTOS.push(newPrivilege);
          } else {
            const existingPrivilege = data?.demandePrivilegeDTOS.find(existingPrivilege => existingPrivilege.privilegeId === privilege.privilegeId);
            if (existingPrivilege.validity !== privilege?.validity || existingPrivilege.accesType !== privilege?.accesType) {
              existingPrivilege.validity = privilege?.validity;
              existingPrivilege.accesType = privilege?.accesType;
            }
          }
        });
        setModal(false);
      }
    }
  };
  // const handleAddP = () => {
  //   if (formValues?.addDemandePrivilegeDtos) {
  //     if (formValues?.addDemandePrivilegeDtos && Array.isArray(formValues.addDemandePrivilegeDtos)) {
  //       formValues.addDemandePrivilegeDtos.forEach((privilege, index) => {
  //         const privilegeExists = data?.demandePrivilegeDTOS.some(existingPrivilege => existingPrivilege.privilegeId === privilege.privilegeId);
  //         if (!privilegeExists) {
  //           const newPrivilege = { privilegeId: privilege?.privilegeId, validity: privilege?.validity, accesType: privilege?.accessType };
  //           data?.demandePrivilegeDTOS.push(newPrivilege);
  //           setModal(false)
  //         } else {
  //           const existingPrivilege = data?.demandePrivilegeDTOS.find(existingPrivilege => existingPrivilege.privilegeId === privilege.privilegeId);
  //           if (!existingPrivilege.validity) {
  //             existingPrivilege.validity = privilege?.validity;
  //             setModal(false)

  //           }
  //         }
  //       });
  //     }
  //   }
  // }
  const handleDeleteP = (privileg) => {
    const filteredData = data?.demandePrivilegeDTOS?.filter(privilege => privilege.privilegeId !== privileg);
    if (filteredData?.length !== 0) {
      data.demandePrivilegeDTOS = filteredData;
    }
  }
  const showAdd = () => {
    setModal(!modal)
  }
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [tooltipOpendelete, setTooltipOpendelete] = useState(false);
  const toggledelete = () => setTooltipOpendelete(!tooltipOpendelete);

  return (
    <div className="row flex justify-center mx-auto">
      <div className="col-md-6 mb-5">
        <dl className="dl">
          <Item title="Structure" value={data.structure} />
          <Item title="Sigle" value={data.companySign} />
          <Item title="Email" value={data.email} />
          <Item title="Localisation géographique" value={data.geographicalLocation} />
          {data.numberIFU && (
            <Item title="Numéro IFU" value={data.numberIFU} />

          )}
          {data.numberRCCM && (
            <Item title="Numéro RCCM" value={data.numberRCCM} />

          )}
          <Item title="Personne à contacter" value={data.personne} />
          <Item title="Numéro de téléphone" value={data.phoneNumber} />
        </dl>
      </div>
      <div className="col-md-6 mb-5">
        <dl className="dl">
          <ItemC title="Catégorie" value={categorie.name} description={categorie?.description} />
          {Array.isArray(data.demandePrivilegeDTOS) && data.demandePrivilegeDTOS.map((privilege, index) => {
            const priv = categorie?.privileges?.find(existingPrivilege => existingPrivilege.id === privilege.privilegeId);
            const prixTotal = priv?.prix * privilege.validity;
            totalPrixTotal += prixTotal;

            return (
              <>
                <ItemP key={index} handleDeleteP={handleDeleteP} tooltipOpendelete={tooltipOpendelete} toggledelete={toggledelete}  prix={categorie.code === 'D' ? categorie?.fraisExceptionnel : prixTotal} index={priv?.id} title={`${priv?.name}`} value={`${TypeAcces(privilege.accesType)} (Validité: ${privilege.validity} mois)`} />

              </>
            )
          }
          )}

        </dl>
        <div className="mt-5 justify-content-end d-flex prives">
          <span className="py-3">Frais des privilèges à payer une fois que votre demande sera validée : <b>{categorie.code === 'D' ? categorie?.fraisExceptionnel : totalPrixTotal}</b>   F CFA</span>

        </div>
        <div className="mt-5 justify-content-end d-flex prives">
          <PrivilegeModal modal={modal} toggle={showAdd} categorie={categorie} handleAddP={handleAddP} setFormValues={setFormValues} formValues={formValues} />
          <Button onClick={handleAdd} className="btn btn-success" id="DisabledAutoHideExample"><i className="ri-add-line"></i>Ajouter d'autres privilèges</Button>
          <Tooltip
            placement="left"
            isOpen={tooltipOpen}
            autohide={false}
            target="DisabledAutoHideExample"
            toggle={toggle}
          >
            Ajouter d'autres privilèges
          </Tooltip>
        </div>
      </div>
      <div className="col-md-12 mb-5">
        <dl className="dl">
          <Item title="Numéro quittance" value={data?.numeroQuitance} />
          <Item title="Montant " value={data?.montantPaiement + ' F CFA'} />
          <Item title="Date paiement" value={moment(data?.datePaiement).format('DD-MM-Y')} />

        </dl>

      </div>
    </div>
  );
}

const Item = ({ title, value }) => {
  return (
    <>
      <dt title={title}>{title} :</dt>
      <dd>{value ?? "---"} </dd>
    </>
  );
};
const ItemC = ({ title, value , description}) => {

  return (
    <>
      <dt title={title}>{title} :</dt>
      <dd>{value +' ('+description+')' ?? "---"} </dd>
    </>
  );
};
const ItemP = ({ title, value, index, handleDeleteP, prix, toggledelete, tooltipOpendelete }) => {

  return (
    <>
      <dt title={title}>{title} :</dt>
      <dd >
       <span className="d-flex">
        {value ?? "---"} 
        <button id="DisabledAutoHideDelete" onClick={() => handleDeleteP(index)}  className="btn btn-sm bg-danger  text-white mx-2"><i class="ri-delete-bin-5-line text-white"></i> </button>
        <Tooltip
          placement="left"
          isOpen={tooltipOpendelete}
          autohide={false}
          target="DisabledAutoHideDelete"
          toggle={toggledelete}
        >
          Supprimé le privilège
        </Tooltip>
        <br />
        </span> 
        <span className="d-flex justify-content-end">{prix} F CFA</span>
      </dd>

    </>
  );
};


