import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function ModalExample({ modal, toggle, data }) {
   
    return (
        <div>

            <Modal isOpen={modal} toggle={toggle} className='modal-dialog modal-fullscreen' >
                {/* <ModalHeader>Modal title</ModalHeader> */}
                <ModalBody>
                    <div className='document'>
                    <iframe src={data} width="100%" height="100%" />
                        {/* <Document file={data}>
                            <Page pageNumber={1} />
                        </Document> */}
                    </div>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={toggle}>
                        Fermer
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ModalExample;