import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import demandeService from '../../../services/demandeService';
import { API_BASIC } from '../../../utility/ApiService';
import ApiRoute from '../../../utility/ApiRoute';
import { getStatusWithColor } from '../../../utility/Utils';
import { Button, Spinner } from 'reactstrap';
import Accreditation from '../../../components/modal/editdemande';
import ModalExample from '../../../components/modal/file';
import authService from '../../../services/authService';

function Suivre(props) {
    let navigate = useNavigate();
    const [loading, setLoading] = useState()
    const [data, setData] = useState()
    const [formInput, setFormInput] = useState('')
    const [message, setMessage] = useState('')
    const [show, setShow] = useState(false)
    const [modal, setModal] = useState(false);

    const handleShow = () => {
        setModal(!modal)
    }
    const handleEdit = async () => {
        setShow(!show)

    }
    const handleCherche = async () => {
        setLoading(true)
        if (formInput.trim() === '') {
            setMessage("Veuillez renseigner le numéro de la demande d'accréditation")
            setLoading(false)

        } else {

            const response = await API_BASIC.get(ApiRoute.gedemandebycode + '/' + formInput, {
                code: formInput,
            }).then(response => {

                setData(response?.data)
                setLoading(false)
                setLoading(false)

            }).catch((error) => {
                setLoading(false)
            });
        }

    }
    const convertToHttps = (url) => {
        // Vérifie si l'URL commence déjà par HTTPS
        if (url.startsWith('http://')) {
            return url; // Si c'est le cas, retourne l'URL inchangée
        }
        // Si l'URL commence par HTTP, la convertit en HTTPS
        if (url.startsWith('http://')) {
            return url.replace('http://', 'http://');
        }
        return url;
    };
    
    return (
        <div>
            <section>
                <div class="container-fluid land-full land-3-banner-full">
                    <div class="container land-container">
                        <div class="land-3-banners">

                            <h1>Suivi dynamique du traitement de la demande d'accréditation</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className='pt-5'>
                <div className="container my-5">
                    <div className="row">

                        <div className='col-md-12 col-12'>
                            <div class="text-muted text-center">
                                <h6 class="mb-3 fw-semibold text-uppercase">Renseigner le numéro de la demande d'accréditation</h6>

                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <div class="row g-2">
                                        <div class="col-sm-10">
                                            <div class="search-box">
                                                <input type="text" class="form-control" id="searchMemberList" placeholder="Saisir le numéro de votre demande d'accréditation" onChange={(e) => setFormInput(e.target.value)} />
                                                <i class="ri-search-line search-icon"></i>
                                            </div>
                                            <div className="text-center">
                                                {message && <span className='text-danger'>{message}</span>}
                                            </div>
                                        </div>

                                        <div class="col-sm-auto ms-auto">
                                            <div class="list-grid-nav hstack gap-1">
                                                {loading ? (
                                                    <Button color="success" className="btn-load">
                                                        <span className="d-flex align-items-center">
                                                            <Spinner size="sm" className="flex-shrink-0"> En cours... </Spinner>
                                                            <span className="flex-grow-1 ms-2">
                                                                En cours...
                                                            </span>
                                                        </span>
                                                    </Button>
                                                ) : (
                                                    <button class="btn btn-success addMembers-modal" onClick={handleCherche}> Verifier</button>

                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {data && (

                                <div class="card">
                                    <div class="card-body">
                                        <div class="text-muted">
                                            <h6 class="mb-3 fw-semibold text-uppercase text-center">Informations de la demande</h6>
                                            <div className="row">

                                                <div className="col-md-12">
                                                    <div class="">
                                                        <div class="table-responsive">
                                                            <dl className="dl">
                                                                <dt>Structure :</dt>
                                                                <dd> {data?.structure} </dd>
                                                                <dt>Situation Géographique :</dt>
                                                                <dd> {data?.geographicalLocation} </dd>
                                                                {/* <dt>N° RCCM  :</dt>
                                                            <dd > {data?.numberRCCM} </dd>
                                                            <dt>N° IFU  :</dt>
                                                            <dd > {data?.numberIFU} </dd> */}
                                                                {/* <dt>Date de la demande  :</dt>
                                                        <dd > 19/12/2020 </dd> */}

                                                            </dl>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 ">
                                                    <div className='d-flex justify-content-center'>

                                                        <h5 class="card-title mb-4">Statut</h5>
                                                        <div class="mx-2">
                                                            {getStatusWithColor(data?.status)}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {data?.requiredDocuments?.map((file, index) => {
                                                if (data?.status === 'ACCEPTER' && file?.nameFile === 'Decision du Directeur ONI') {
                                                    return (
                                                        <div class="col-xxl-3 col-lg-3 col-12" key={index}>
                                                            <ModalExample modal={modal} toggle={handleShow} data={convertToHttps(file?.urlFile)} />

                                                            <div class="border rounded border-dashed p-2">
                                                                <div class="d-flex align-items-center">
                                                                    <div class="flex-shrink-0 me-3">
                                                                        <div class="avatar-sm">
                                                                            <div class="avatar-title bg-light text-secondary rounded fs-24">
                                                                                <i class="ri-file-ppt-2-line"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <p>{file?.nameFile} </p>
                                                                    <div class="flex-shrink-0 ms-2">
                                                                        <div class="d-flex gap-1">
                                                                            <button type="button" class="btn btn-icon text-muted btn-sm fs-18"></button>
                                                                            <div class="dropdown">
                                                                                <button class="btn btn-icon text-muted btn-sm fs-18 dropdown" onClick={handleShow} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <i class="ri-eye-fill me-2 align-bottom text-muted"></i>
                                                                                </button>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                return null
                                            })}
                                            {data?.status === 'REJETER_PARTIEL' && (
                                                <div className="pt-3 border-top border-top-dashed mt-4 text-center justify-content-center">
                                                    {(() => {
                                                        const file = data?.requiredDocuments?.find(file => file?.nameFile === 'Motif de rejet partiel');
                                                        if (file) {
                                                            return (
                                                                <div className="col-xxl-3 col-lg-3 col-12" key={data?.id}>
                                                                    <ModalExample modal={modal} toggle={handleShow} data={convertToHttps(file?.urlFile)} />
                                                                    <div className="border rounded border-dashed p-2">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="flex-shrink-0 me-3">
                                                                                <div className="avatar-sm">
                                                                                    <div className="avatar-title bg-light text-secondary rounded fs-24">
                                                                                        <i className="ri-file-ppt-2-line"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <p>{file?.nameFile}</p>
                                                                            <div className="flex-shrink-0 ms-2">
                                                                                <div className="d-flex gap-1">
                                                                                    <button type="button" className="btn btn-icon text-muted btn-sm fs-18"></button>
                                                                                    <div className="dropdown">
                                                                                        <button className="btn btn-icon text-muted btn-sm fs-18 dropdown" onClick={handleShow} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                            <i className="ri-eye-fill me-2 align-bottom text-muted"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    })()}
                                                    <div className="land-event-date desc">
                                                        <Accreditation data={data} toggle={handleEdit} modal={show} />
                                                        <button type="button" onClick={handleEdit} className="btn btn-info">Modifier</button>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>

                                </div>
                            )}

                        </div>
                        {/* <div class="col-md-4 col-12">
                            <div className='sect1 justify-content-center'>
                                <i className='ri-information-line mb-5'></i>
                                <h2>Besoins d'informations</h2>
                                                            <dl className="dl my-2">
                                                                <dt>Email :</dt>
                                                                <dd>oni@oni.bf</dd>
                                                                <dt>Téléphone :</dt>
                                                                <dd> 25 49 77 95 </dd>
                                                                <dt>Téléphone :</dt>
                                                                <dd> 25 49 77 95 </dd>

                                                            </dl>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

        </div>
    );
}

export default Suivre;