import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Input } from 'reactstrap';
function DemandeSucces({ modal }) {

    return (
        <div>

            <Modal isOpen={modal} >
                {/* <ModalHeader>Modal title</ModalHeader> */}
                <ModalBody>

                    <div>
                        {/* <lord-icon
                                        src="https://cdn.lordicon.com/lupuorrc.json"
                                        trigger="loop"
                                        colors="primary:#121331,secondary:#08a88a"
                                        style="width:120px;height:120px">
                                    </lord-icon> */}

                        <div class="mt-4 text-center">
                            <h4 class="mb-3">Demande d'accréditation</h4>
                            <p class="text-muted mb-4"> Votre demande d'accréditation a été soumise avec succès.</p>
                            <p class="text-muted mb-4 justify-content-center"> Un numéro unique de votre de demande vous a été envoyé par mail pour suivre le traitement de votre demande. Veuillez consulter votre boite mail </p>
                            <div class="hstack gap-2 justify-content-center">
                                {/* <a href="javascript:void(0);" class="btn btn-link link-success fw-medium" data-bs-dismiss="modal"><i class="ri-close-line me-1 align-middle"></i> Close</a> */}
                                <Link to="/suivre" class="btn btn-success">Continuer</Link>
                            </div>
                        </div>
                    </div>

                </ModalBody>
            </Modal>
        </div>
    );
}

export default DemandeSucces;