import React from "react";
import { getUserData } from "../utility/Utils";
import { Navigate } from "react-router-dom";

const RequireAuth=({ children }) =>{
    const  authed = getUserData();
    return authed!=null ? children : <Navigate to="/" replace />;
  }

  export default RequireAuth;
  