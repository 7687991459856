import React, { useEffect, useState } from "react";
import "./payment.css";
import { Col, FormGroup, Input, Label,  Row } from "reactstrap";
import { useFormikContext } from "formik";
import { API_BASIC } from "../../../utility/ApiService";
import ApiRoute from "../../../utility/ApiRoute";
import { OverlayTrigger,Popover } from "react-bootstrap";

export default function Cadre({ errors, values, handleChange }) {
    const formik = useFormikContext();
    const [form, setForm] = useState([]);
    const [maxDate, setMaxDate] = useState('');
    const [categorie, setCategorie] = useState([]);

    useEffect(() => {
        (async () => {
            // setLoading(true)
            await API_BASIC.get(ApiRoute.categoriesall + '/' + values?.categorieId).then(response => {
                const { data } = response;
                setCategorie(data)
                // setLoading(false)
            }).catch((error) => {
                // setLoading(false)

            });
        })()
    }, [values])
    useEffect(() => {
        // Initialize form state based on initial values
        setForm(values.demandePrivilegeDTOS.map(item => ({
            privilegeId: item.privilegeId,
            validity: item.validity,
            accesType: item.accesType,
            privilege: categorie?.privileges?.find(existingPrivilege => existingPrivilege.id === item.privilegeId),
            applicationName: item.applicationName || '',
            consumerModule: item.consumerModule || '',
            apiConnectionDiagramFile: item.apiConnectionDiagramFile || []
        })));
    }, [values.demandePrivilegeDTOS]);

    useEffect(() => {
        // Set maximum date to today
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setMaxDate(formattedDate);
    }, []);

    const handleValue = ({ type, value, index }) => {
        const updatedForms = form.map((f, i) =>
            i === index ? { ...f, [type]: value } : f
        );

        setForm(updatedForms);

        // Filter only the necessary properties: privilegeId, applicationName, consumerModule, requiredDocuments
        const formData = updatedForms.map(f => ({
            privilegeId: f.privilegeId,
            validity: f.validity,
            accesType: f.accesType,
            applicationName: f.applicationName,
            consumerModule: f.consumerModule,
            apiConnectionDiagramFile: f.apiConnectionDiagramFile
        }));
        formik.setFieldValue("demandePrivilegeDTOS", formData);
    };

    const handleFileChange = (event, fieldName, index) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(',')[1];
                const updatedDocuments = [
                    ...form[index].apiConnectionDiagramFile,
                    {
                        nameFile: fieldName,
                        typeFichier: file.type,
                        imageBase64: base64String,
                    },
                ];
                handleValue({ type: 'apiConnectionDiagramFile', value: updatedDocuments, index });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleApplicationNameChange = (event, index) => {
        const value = event.target.value;
        handleValue({ type: "applicationName", value, index });
    };

    const handleConsumerModuleChange = (event, index) => {
        const value = event.target.value;
        handleValue({ type: "consumerModule", value, index });
    };
    const hasAccesApi = values.demandePrivilegeDTOS.some(item => item.accesType === 'ACCES_API');
    const popover=({title, body}) => {
        return(
            <Popover id="popover-basic">
            <Popover.Header as="h3">{title} </Popover.Header>
            <Popover.Body>
                <p>{body} </p>
               
            </Popover.Body>
        </Popover>

        )
    };
    return (
        <div className="my-5">
            <div className="row payment-container mx-auto d-flex">
                <Row>
                    <Col xxl={hasAccesApi ? 6 : 12}>
                        <FormGroup>
                            <Label htmlFor="reasonRequest">
                                Motif de la demande
                                <span className='text-danger'> *</span>
                            </Label>
                            <Input
                                id="reasonRequest"
                                name="reasonRequest"
                                required
                                value={values.reasonRequest}
                                onChange={handleChange}
                                placeholder="Motif de la demande"
                                type="textarea"
                            />
                            {errors.reasonRequest && (
                                <p className="text-danger">{errors.reasonRequest}</p>
                            )}
                        </FormGroup>
                    </Col>
                    {hasAccesApi && values.demandePrivilegeDTOS.map((item, index) => {
                        if (item.accesType === 'ACCES_API') {
                            const privilegeName = categorie?.privileges?.find(existingPrivilege => existingPrivilege.id === item.privilegeId)?.name;

                            return (
                                <React.Fragment key={index}>
                                    <Col xxl={6}>
                                        <FormGroup>
                                            <Label htmlFor={`demandePrivilegeDTOS[${index}].applicationName`} className="d-flex">
                                                Nom de(s) application(s) utilisant le {privilegeName}
                                                <span className='text-danger'> *</span>
                                                <OverlayTrigger trigger="click" placement="top" overlay={popover({title:"Nom de(s) application(s)", body:"Nom de(s) application(s) qui vont utiliser l'accès API pour consulter l'identité"})} >
                                                    <i className='ri-information-line mx-2'></i>
                                                </OverlayTrigger>
                                            </Label>
                                            <Input
                                                id={`demandePrivilegeDTOS[${index}].applicationName`}
                                                name={`demandePrivilegeDTOS[${index}].applicationName`}
                                                required
                                                value={item.applicationName}
                                                onChange={(e) => handleApplicationNameChange(e, index)}
                                                placeholder="Nom de(s) l’application(s)"
                                                type="textarea"
                                            />
                                            {errors.demandePrivilegeDTOS?.[index]?.applicationName && (
                                                <p className="text-danger">{errors.demandePrivilegeDTOS[index].applicationName}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col xxl={6}>
                                        <FormGroup>
                                            <Label htmlFor={`demandePrivilegeDTOS[${index}].consumerModule`}>
                                                Liste des modules consommateurs de données du {privilegeName}
                                                <span className='text-danger'> *</span>
                                                <OverlayTrigger trigger="click" placement="top" overlay={popover({title:"Liste des modules consommateurs de données", body:"Les modules consommateurs de données d'API peuvent varier en fonction de l'application et de son architecture. Voici une liste des modules communs dans les applications qui consomment des données d'API: Module de Gestion des Utilisateurs , Module de Paiement, ...."})} >
                                                    <i className='ri-information-line mx-2'></i>
                                                </OverlayTrigger>
                                            </Label>
                                            <Input
                                                id={`demandePrivilegeDTOS[${index}].consumerModule`}
                                                name={`demandePrivilegeDTOS[${index}].consumerModule`}
                                                required
                                                value={item.consumerModule}
                                                onChange={(e) => handleConsumerModuleChange(e, index)}
                                                placeholder="Liste des modules consommateurs de données"
                                                type="textarea"
                                            />
                                            {errors.demandePrivilegeDTOS?.[index]?.consumerModule && (
                                                <p className="text-danger">{errors.demandePrivilegeDTOS[index].consumerModule}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                Schéma de connexion API du {privilegeName}
                                                <span className='text-danger'> *</span>
                                                <OverlayTrigger trigger="click" placement="top" overlay={popover({title:" Schéma de connexion API", body:" Un schéma de connexion API (Application Programming Interface) fait référence à la manière dont une application cliente se connecte et interagit avec une API. Il comprend des aspects de sécurité, de gestion des accès, de communication, et de structure des requêtes et des réponses. "})} >
                                                    <i className='ri-information-line mx-2'></i>
                                                </OverlayTrigger>
                                            </Label>
                                            <Input
                                               
                                                name={`demandePrivilegeDTOS[${index}].apiConnectionDiagramFile`}
                                                onChange={(event) => handleFileChange(event, "Schéma de connexion API du " + privilegeName, index)}
                                                type="file"
                                                required
                                            />
                                            {errors.demandePrivilegeDTOS?.[index]?.apiConnectionDiagramFile && (
                                                <p className="text-danger">{errors.demandePrivilegeDTOS[index].apiConnectionDiagramFile}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </React.Fragment>
                            );
                        }
                        return null;
                    })}
                    
                </Row>
            </div>
        </div>
    );
}
