export default {
    index:'/index',
    login:'/user-connexion/get-2fa-code',
    code:'/user-connexion/access/token',
    histoiques: `/histoiques`,
    users: `/users/sub-account`,
    usersbyid: `/user/get`,
    demande: `/demande`,
    identity: `/identity`,
    postSubcount: `/sub-account`,
    demandeId: `/demand/user/get`,
    gedemandebyId: `/demand/user/get`,
    gedemandebycode: `/codeDemande`,
    postdemande: `/demand`,
    demanderenouvelle: `/demand/renewal`,
    demandebyId:'/demand/user/get',
    alldemande: `/demand/all`,
    privileges: `/privileges`,
    statistiqueAdmin: `/statistics/admin`,
    statistiqueUser: `/statistics/user`,
    config: `/config`,
    editdemande: `/demand/update`,
    avisCil: `/demand/avis/cil`,
    reject: `/reject`,
    resendcode: `/resend-code`,
    changePasse: `/user/change-password`,
    validedemande: `/accept/demand`,
    renouvellementpost: `renouveler/demand`,
    demandeavisCil: `/demandes/avis-cil`,
    alldemandeattente: `/demand/list`,
    categories: `/categories`,
    categoriesall: `/categories/privilege/prix`,
    loginK: 'user-connexion/get-2fa-code',
    userenable:'/user/enable-disable',
    loginOni:'https://oni-keyload.switch-maker.net/realms/oni-bf/protocol/openid-connect/token',
    logout: '/user-connexion/access/logout',

    handlerError: (error) => _handlerError(error)
}

const _handlerError = (error) => {
    const response = error.response
    if(response) {
        const messageData = response?.data?.messageData
        const message = response?.data?.message
        if(messageData) {
            return getMessage(messageData)
        } else if(message) {
            return getMessage(message)
        } else {
            return response?.statusText
        }
    } else {
        return 'Oops !! Léger souci.'
    }
}

const getMessage = (message = {}) => {
    let _message = '';
    if(typeof message == 'object') {
        for (const key in message) {
            if (message.hasOwnProperty(key)) {
                _message+= ' '+ message[key];
            }
        }
        // _message
    } else {
        _message = message;
    }
    return _message
}