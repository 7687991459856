import React from "react";
import ApiRoute from "../utility/ApiRoute";
import API_BASIC from "../utility/ApiService";
import { getUserData } from "../utility/Utils";

const demandeService = {
   
    async getdemandes() {
        const tokenBearer = getUserData()
        const response = await API_BASIC.get(ApiRoute.alldemande);
        const data = response;
        return data;
    },
   
    async getDemande(code) {
        const response = await API_BASIC.get(ApiRoute.gedemandebycode + '/' + code, {
            code: code,
        });
        return response;
    },
   
    async postDemande(data) {
        const response = await API_BASIC.post(ApiRoute.postdemande, {
            email: data.email,
            personne: data.personne,
            phoneNumber: data.phoneNumber,
            companySign: data.companySign,
            geographicalLocation: data.geographicalLocation,
            numberRCCM: data.numberRCCM,
            numberIFU: data.numberIFU,
            structure: data.structure,
            requiredDocuments: data.requiredDocuments,
            categorieId: data.categorieId,
            demandePrivilegeDTOS: data.demandePrivilegeDTOS
        }).then(response => {
               
            const data_1 = response;
            return data_1;
        });
        // const data_1 = response;
        // console.log(data_1);
        // return data_1;
    },
    async patchFaitiere(data) {
        const response = await API_BASIC.patch(ApiRoute.faitieres + '/' + data.id, {
            id: data.id,
            nom: data.nom,
            telephone: data.telephone,
            email: data.email,
            pays_id: data.pays_id,
            region_id: data.region_id,
            logo: data.logo,
            sigle: data.sigle,
            date_creation: data.date_creation,
            secteur_activite: data.secteur_activite,
            problematique_agricolte: data.problematique_agricolte,
        });
        const data_1 = response?.data;
        return data_1;
    },
    async deleteFaitiere(id) {
        const response = await API_BASIC.delete(ApiRoute.faitieres + '/' + id, {
            id: id,
        });
        const data = response?.data;
        return data;
    },
   

    async getFaitiereResponsable() {
        const response = await API_BASIC.get(ApiRoute.faitierer_detail);
        const data = response?.data?.data;
        return data;
    },

    async patchFaitiereShow(data) {
        const response = await API_BASIC.patch(ApiRoute.faitiere_edition + '/' + data.id, {
            id: data.id,
            nom: data.nom,
            telephone: data.telephone,
            email: data.email,
            sigle: data.sigle,
            secteur_activite: data.secteur_activite,
            date_creation: data.date_creation,
        }).then(response => {
               
            const data_1 = response;
            return data_1;
        });
      
    }

}

export default  demandeService;