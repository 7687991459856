import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import constant from "../../utility/constant"

const Navdata = () => {
    // let navigate = useNavigate();

    const appConstant = {...constant}
    const role = {...appConstant?.appRole}
    const menuItems = [
  
        {
            id: "dashboard",
            icon: "ri-dashboard-line",
            label: "Tableau de bord",
            link: "/dashboard",
            permissions:['all']
        },
       
        {
            id:'SousCompte',
            icon :'ri-file-user-line',
            label:'Comptes utilisateurs',
            link:'/comptes',
            permissions:[role?.manager]
          },
        {
            id:'Renouveler',
            icon :'ri-pages-line',
            label:'Accréditation',
            link:'/accreditation',
            permissions:[role?.manager]
          },
      
         
          {
                id: "demandes",
                label: "Demandes",
                icon: "ri-file-list-3-line",
                link: "/#",
                permissions:[role.cil],
                subItems: [
                    {
                        id: "recu",
                        label: "Reçues",
                        link: "/demandes/recues",
                        parentId: "demandes",
                        permissions:[role.cil],
                    },
                    {
                        id: "renouvellement",
                        label: "Renouvellements",
                        link: "/demandes/renouvellement",
                        parentId: "demandes",
                        permissions:[role.cil],
                    },
                    {
                        id: "traiter",
                        label: "Traitées",
                        link: "/demandes/accptées",
                        parentId: "demandes",
                        permissions:[role.cil],
                    }
                    // {
                    //     id: "rejeter",
                    //     label: "Demandes rejétées",
                    //     link: "/demandes/rejetees",
                    //     parentId: "demandes",
                    //     permissions:[role.cil],
                    // },
                   
                ],
            },


    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;