import React from "react";
import constant from "../../utility/constant";
import { getUserRole } from "../../utility/Utils";
const Footer = () => {
    const appConstant = { ...constant };
    const userrole = { ...appConstant?.appRole };
        const role = getUserRole()
    const currentDate = new Date().getFullYear()
    return (
        <footer className={(role==userrole?.manager || role === userrole?.cil)?("footer"):("footer-"+userrole?.sousUser)}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        © ONI {currentDate}
                    </div>
                    <div className="col-sm-6">
                        <div className="text-sm-end d-none d-sm-block">
                            Tous droit reservé
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
export default Footer
