import React, { useRef, useState } from 'react';
import { Input, Label, FormGroup, Col, Row, FormText } from 'reactstrap';
import { useFormikContext } from "formik";

function PrivilegeInfo({ errors, values, handleChange }) {
    const formFiles = useRef([])
    const formik = useFormikContext();

    const onChangeFile = (event, fieldName) => {

        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(',')[1];

                formik.setFieldValue("requiredDocuments", [...formik.values.requiredDocuments, {
                    nameFile: fieldName,
                    typeFichier: file.type,
                    imageBase64: base64String,
                    size:file.size
                }]);
            };
            reader.readAsDataURL(file);
        }
    };


    return (
        <div className='mx-4'>
            <Row className='py-3'>
                <Col md={6}>
                    <FormGroup>
                        <label htmlFor="inputtext" class="form-label">Document attestant l'existence juridique de l'entité demanderesse
                            <span className='text-danger'> *</span>
                        </label>

                        <Input
                            name='requiredDocuments'
                            onChange={(event) => onChangeFile(event, "Document attestant l'existence juridique")}
                            type="file"
                            required
                        />
                        <FormText>
                            La taille du fichier doit être inferieure ou égale à 5Mo
                        </FormText>
                        {formik.errors.requiredDocuments &&
                            <div className="text-danger">{formik.errors.requiredDocuments} </div>
                        }
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <label htmlFor="inputtext" class="form-label">Demande timbrée
                            <span className='text-danger'> *</span>
                        </label>

                        <Input
                            name='inputtext'
                            // onChange={onChangeFile}
                            onChange={(event) => onChangeFile(event, "Demande timbrée ")}
                            type="file"
                            required
                        />
                        <FormText>
                            La taille du fichier doit être inferieure ou égale à 5Mo
                        </FormText>
                        {formik.errors.requiredDocuments &&
                            <div className="text-danger">{formik.errors.requiredDocuments} </div>
                        }
                    </FormGroup>
                </Col>

                {/* <Col md={6}>
                    <FormGroup>
                        <label htmlFor ="inputtext" class="form-label">Importer la quittance de paiement
                        </label>

                        <Input
                            onChange={handleFileChange}
                            type="file"
                            required
                        />
                    </FormGroup>
                </Col> */}

            </Row>
        </div>
    );
}

export default PrivilegeInfo;