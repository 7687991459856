import React, { useEffect, useState } from "react";
import "./payment.css";
import Moov from "../../../assets/moov.png";
import Orange from "../../../assets/money.jpg";
import { Col, Form, Tab, Tabs } from "react-bootstrap";
import { FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { useFormikContext } from "formik";

export default function Payment({ errors, values, handleChange }) {
 
  const [active, setActive] = React.useState(0);
  const [formFile, setFormFile] = useState([])
  const formik = useFormikContext();

  const [maxDate, setMaxDate] = useState('');

  useEffect(() => {
      // Obtenir la date actuelle
      const today = new Date();
      // Formater la date en 'YYYY-MM-DD'
      const formattedDate = today.toISOString().split('T')[0];
      setMaxDate(formattedDate);
  }, []);


const handleFileChange = (event, fieldName) => {
  
  const file = event.target.files[0];
  if (file) {
      const reader = new FileReader();
      reader.onload = () => {
          const base64String = reader.result.split(',')[1];

          formik.setFieldValue("fichiers", [...formik.values.fichiers, {
            nameFile:fieldName,
              typeFichier: file.type,
              imageBase64: base64String,
              size:file.size
          }]);
      };
      reader.readAsDataURL(file);
  }
};
  const [key, setKey] = useState('home');
  return (
    <div className="my-5">
      <div className="row payment-container mx-auto d-flex">
        <div className="payment-title text-center my-2">
          <h5>Choisissez votre moyen de paiement</h5>
        </div>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3 justify-content-center"
        >
          <Tab eventKey="home" title="Paiement physique">
            <Row>

                <Col xxl={6}>
                <FormGroup>
                        <Label htmlFor ="numeroQuitance">
                        Numéro quittance
                            <span className='text-danger'> *</span>
                        </Label>
                        <Input
                            id="numeroQuitance"
                            name="numeroQuitance"
                            required
                            value={values.numeroQuitance}
                            onChange={handleChange}
                            placeholder="Numéro quittance"
                            type="text"
                        />
                        {errors.numeroQuitance && (
                            <p className="text-danger">{errors.numeroQuitance}</p>
                        )}
                    </FormGroup>
                  
                </Col>
                <Col xxl={6}>
                <FormGroup>
                        <Label htmlFor ="montantPaiement">
                        Montant
                            <span className='text-danger'> *</span>
                        </Label>
                        <Input
                            id="montantPaiement"
                            name="montantPaiement"
                            required
                            value={values.montantPaiement}
                            onChange={handleChange}
                            placeholder="Montant"
                            type="number"
                            readOnly
                        />
                        {errors.montantPaiement && (
                            <p className="text-danger">{errors.montantPaiement}</p>
                        )}
                    </FormGroup>
                  
                </Col>
                <Col xxl={6}>
                <FormGroup>
                        <Label htmlFor ="datePaiement">
                        Date de paiement
                            <span className='text-danger'> *</span>
                        </Label>
                        <Input
                            id="datePaiement"
                            name="datePaiement"
                            required
                            value={values.datePaiement}
                            onChange={handleChange}
                            placeholder="Date de paiement"
                            max={maxDate}
                            type="date"
                        />
                        {errors.datePaiement && (
                            <p className="text-danger">{errors.datePaiement}</p>
                        )}
                    </FormGroup>
                  
                </Col>
               
                <Col md={6}>
                    <FormGroup>
                        <label htmlFor ="inputtext" class="form-label">Quittance de paiement
                            <span className='text-danger'> *</span>
                        </label>

                        <Input
                        name='fichiers'
                            // onChange={handleFileChange}
                            onChange={(event) => handleFileChange(event, "Quittance des frais de dossier")}
                            type="file"
                            required
                        />
                        <FormText>
                            La taille du fichier doit être inferieure ou égale à 5Mo
                        </FormText>
                         {errors.fichiers && (
                            <p className="text-danger">{errors.fichiers}</p>
                        )}
                    </FormGroup>
                </Col>

            </Row>
          </Tab>

          <Tab eventKey="contact" title="Paiement par Mobile money">
            <div className="text-center">
            <p>Service indisponible pour le moment</p>

            </div>
            {/* <div className="payment-header mx-auto">
              <div
                className={"mean-box" + (active === 0 ? " active" : "")}
                onClick={() => setActive(0)}
              >
                <img src={Moov} alt="" />
                <h5 className="payment-title">Moov Money</h5>
              </div>
              <div
                className={"mean-box" + (active === 1 ? " active" : "")}
                onClick={() => setActive(1)}
              >
                <img src={Orange} alt="" />
                <h5 className="payment-title">Orange Money</h5>
              </div>
            </div>
            <div className="payment-container d-flex">
              {active === 0 ? (
                <form action="" className="payment-form">
                  <h5 className="text-center">
                    Moov Money
                  </h5>
                  <div className="payment-input-group">
                    <label for ="">Numéro de téléphone</label>
                    <input type="text" placeholder="Numéro de téléphone" />
                  </div>
                  <div className="payment-input-group">
                    <label for ="">Code OTP</label>
                    <input type="text" placeholder="OTP" />
                  </div>
                </form>
              ) : (
                <form action="" className="payment-form">
                  <h5 className="text-center">
                    Orange Money
                  </h5>
                  <div className="payment-input-group">
                    <label for ="">Numéro de téléphone</label>
                    <input type="text" placeholder="Numéro de téléphone" />
                  </div>
                  <div className="payment-input-group">
                    <label for ="">Code OTP</label>
                    <input type="text" placeholder="OTP" />
                  </div>
                </form>
              )}
            </div> */}
          </Tab>
        </Tabs>

      </div>
    </div>
  );
}
