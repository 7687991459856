import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import CommonLayout from '../layouts/common';
import PrivateLayout from '../layouts/private';
// import OniLayout from '../layouts/oni';
// import NoMatchPage from '../pages/common/NoMatch';
import { privateRoutes, publicRoutes } from './allRoutes';
// import Loader from '../components/loader';
import { getUserData, getUserRole } from '../utility/Utils';
import RequireAuth from './requireAuth';
import constant from "../utility/constant";
import Loader from '../components/loader/Loader';
import Demande from '../pages/common/demande';
import Suivre from '../pages/common/suivre';
import NoMatchPage from '../pages/common/blank';


const IndexFront = React.lazy(() => import("../pages/common"));
const MotPasse = React.lazy(() => import("../pages/common/password"));
const Password = React.lazy(() => import("../pages/private/backoffice/comptes/password"));
const Dashboard = React.lazy(() => import("../pages/private/backoffice"));
// const NoMatchPage = React.lazy(() => import("../pages/common/NoMatch"));

const MainRoutes = () => {
    const userData = getUserData()
    const userRole = getUserRole()
    const [menuItem, setMenuItem] = useState([]);
    const [menuItemP, setMenuItemP] = useState([]);
    // const [userRole, setuserRole] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            let _menuItems = privateRoutes.filter(
                (item) =>
                    item.permissions.includes(userRole) ||
                    item.permissions.includes("all")
            );
            setMenuItem(_menuItems);
            setMenuItemP(publicRoutes)
            setIsLoading(false);

        }, 1000)
    }, [menuItem])
    return (
        <>
            {
                isLoading ? (<>
                    <div id="status">
                    &nbsp;
                    </div></>) : (<>
                        <React.Suspense >
                            <Routes>
                                {}
                                <Route element={<PrivateLayout />}>
                                    {menuItem.map((route, index) => {
                                        const ElementPage = route.component;
                                        const permissions = route.permissions;
                                        return (
                                            <Route

                                                path={route.path}
                                                key={index}

                                                element={
                                                    <React.Suspense >

                                                        <RequireAuth>
                                                        {(userData?.user?.resource_access.oni.roles[0]!='client' && userData?.user?.mdpDefaut === 'true') ? (
                                                                <Password />
                                                            ) : (
                                                                <ElementPage />
                                                            )}
                                                        </RequireAuth>



                                                    </React.Suspense>
                                                }
                                            />
                                        )
                                    })}
                                </Route>
                                <Route element={<CommonLayout />}>
                                    <Route
                                        path="/"
                                        element={
                                            <React.Suspense >
                                                <IndexFront />
                                            </React.Suspense>
                                        }
                                    />
                                    {/* <Route
                                        path="/admin/oni"
                                        element={
                                            <React.Suspense >
                                                <IndexOni />
                                            </React.Suspense>
                                        }
                                    /> */}
                                    <Route
                                        path="/faire-demande"
                                        element={
                                            <React.Suspense >
                                                <Demande />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/suivre"
                                        element={
                                            <React.Suspense >
                                                <Suivre />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/password"
                                        element={
                                            <React.Suspense >
                                                <MotPasse />
                                            </React.Suspense>
                                        }
                                    />
                                </Route>
                                {/* <Route path="*" element={<NoMatchPage />} /> */}

                            </Routes >
                        </React.Suspense>
                    </>)
            }
        </>
    );
}

export default MainRoutes;
