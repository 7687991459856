import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './header';
// import Sidebar from './sidebar';
import Footer from './footer';
import Sidebars from './sidebars';
import { getUserRole } from '../../utility/Utils';
import constant from '../../utility/constant';

const PrivateLayout = () => {
    const appConstant = { ...constant };
const userrole = { ...appConstant?.appRole };
    const role = getUserRole()
    return (
        <div id="layout-wrapper">
           
                <Header />
        {(role==userrole?.manager || role === userrole?.cil) &&(

            <Sidebars/>
        )}
            <div className="vertical-overlay"></div>
            <div className={(role==userrole?.manager || role === userrole?.cil)?("main-content"):("main-content-"+userrole?.sousUser)}>
                <div className={(role==userrole?.manager || role === userrole?.cil)?("page-content"):("page-content-"+userrole?.sousUser)}>
                    <div className="">
                        <Outlet />

                    </div>
                </div>

                <Footer/>
            </div>


        </div>
    );
}

export default PrivateLayout;
