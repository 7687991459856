import ApiRoute from "../utility/ApiRoute";
import API_BASIC from "../utility/ApiService";
import React from "react";

class dataService  {
    
       async getPrivileges (){
        const response = await API_BASIC.get(ApiRoute.privileges);
           const data = response;
           return data;
    }
    
    getRegion (id) {
        return API_BASIC.get(ApiRoute.villes+'/'+id, {id:id})
            .then(response => {
                
                const data = response?.data?.data?.regions
                return data;
            });
    }
    getProvince (id) {
        return API_BASIC.get(ApiRoute.province+'/'+id, {id:id})
            .then(response => {
                const data = response?.data?.data?.provinces
                return data;
            });
    }
    

    getFiliaires(){
        return API_BASIC.get(ApiRoute.filiaires)
            .then(response => {
          
                const data = response?.data?.data?.filiaires
                return data;
            });
    }
    getFilieres(){
        return API_BASIC.get(ApiRoute.filiere)
            .then(response => {
          
                const data = response?.data?.data?.filiaires
                return data;
            });
    }
    postFiliaires(data){
        return API_BASIC.post(ApiRoute.filiaires,{libelle:data?.libelle})
            .then(response => {
                const data = response?.data
                return data;
            });
    }
    importFiliaires(data){
        return API_BASIC.post(ApiRoute.filiaire,{fichier:data?.fichier})
            .then(response => {
                const data = response?.data
                return data;
            });
    }
    deleteFiliaire(id){
        return API_BASIC.delete(ApiRoute.filiaires+'/'+id,{id:id})
            .then(response => {
                const data = response?.data
                return data;
            });
    }
    getParametre(){
        return API_BASIC.get(ApiRoute.parametre)
            .then(response => {
                const data = response?.data?.data?.parametre
                return data;
            });
    }
    getParametres(){
        return API_BASIC.get(ApiRoute.parametres)
            .then(response => {
                const data = response?.data?.data?.parametre
                return data;
            });
    }
    patchParametre(data){
        return API_BASIC.patch(ApiRoute.parametre+ "/" + data.id, {
            id: data.id,
            nom: data.nom,
            sigle: data.sigle,
            telephone: data.telephone,
            email: data.email,
            logo: data.logo,
        })
            .then(response => {
                const data = response?.data?.data?.parametre
                return data;
            });
    }

}

export const getCategories =  async ()  => {
    const response = await API_BASIC.get(ApiRoute.categories).then(response => {
       
        const data = response;
        return data; 
    });       
}

export default new dataService();