// var server = 2;
// let BACK_ENPOINT_USER = ''
// let BACK_ENPOINT = ''
// let BACK_ENPOINT_CONSULTATION= ''
// if (server === 1) {
//     BACK_ENPOINT_USER = 'oni/api/v1'
//     BACK_ENPOINT = 'api/v1'
//     BACK_ENPOINT_CONSULTATION='oni/api/v1'

// } else {
//      BACK_ENPOINT_USER = 'USER-SERVICE/oni/api/v1'
//     BACK_ENPOINT = 'DEMANDE-SERVICE/api/v1'
//     BACK_ENPOINT_CONSULTATION = 'DEMANDE-SERVICE/api/v1'
// }

// export const DOMAIN = 'https://oni.switch-maker.net'
const BACK_ENPOINT_USER = 'oni/api/v1'
const BACK_ENPOINT = 'api/v1'

// export const DOMAIN = 'http://192.168.2.108:8085'
// export const DOMAIN_USER = 'http://192.168.2.108:8086'
// export const DOMAIN_CONSULTATION = 'http://192.168.2.108:8084'


export const DOMAIN = 'https://oni-api-demandes.switch-maker.net'
export const DOMAIN_USER = 'https://oni-api-users.switch-maker.net'
export const DOMAIN_CONSULTATION = 'https://oni-api-consultations.switch-maker.net'

export default {
    url: `${DOMAIN}/${BACK_ENPOINT}`,
    url_user: `${DOMAIN_USER}/${BACK_ENPOINT_USER}`,
    url_password: `${DOMAIN_USER}`,
    url_consultation: `${DOMAIN_CONSULTATION}/${BACK_ENPOINT_USER}`,
    mediaUrl: `${DOMAIN}`,
    tokenType: 'Bearer',
    storageUserDataKeyName: 'USER-DATA',
    storageUserTokenDataKeyName: 'USER-TOKEN',
    appRole: {
        admin: '',
        manager: 'gestionnaire',
        sousUser: 'client',
        cil: 'cil',
        all: 'all'
    }
}