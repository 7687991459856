
import React from 'react';
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from "react-router-dom";
import MainRoutes from './routes';
import { useEffect } from 'react';
import './App.css';
import { Helmet } from 'react-helmet';
function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <MainRoutes />
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
