import React, { useEffect, useState } from 'react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { Input, Label, FormGroup, Col, Row, Spinner } from 'reactstrap';
import { useFormikContext } from "formik";
import { API_BASIC } from '../../../utility/ApiService';
import ApiRoute from '../../../utility/ApiRoute';

function Accreditation({ errors, values, handleChange }) {

    const [privileges, setPrivileges] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [privilegeStates, setPrivilegeStates] = useState([]);
    const [form, setForm] = useState([]);
    
    const formik = useFormikContext();

    useEffect(() => {
        (async () => {
            setLoading(true);
            await API_BASIC.get(ApiRoute.categoriesall).then(response => {
                const { data } = response;
                setCategories(data);
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
            });
        })();
    }, []);

    useEffect(() => {
        if (values.categorieId) {
            const selectedCategoryData = categories.find(category => category.id === values.categorieId);
            const selectedCategoryPrivileges = selectedCategoryData?.privileges.map(privilege => ({
                value: privilege.id,
                description: privilege.description,
                label: privilege.name,
                categorie: selectedCategoryData.name,
                prix: privilege.prix
            })) || [];
            setPrivileges(selectedCategoryPrivileges);
        }
    }, [categories, values.categorieId]);

    useEffect(() => {
        setPrivilegeStates((prev) => privileges.map(p => ({
            id: p.value,
            isActive: values.demandePrivilegeDTOS.some(d => d.privilegeId === p.value),
            categorie: p.categorie,
            prix: p.prix
        })));
        setForm(values.demandePrivilegeDTOS);
    }, [privileges, values.demandePrivilegeDTOS]);

    const onSelect = (e) => {
        const typeIdSelected = e.target.value;
        formik.setFieldValue("categorieId", typeIdSelected);
        const selectedCategoryData = categories.find(category => category.id === typeIdSelected);
        formik.setFieldValue("categortyName", selectedCategoryData?.code);
    };

    const handlePrivilegeChange = (index) => {
        const newPrivilegeStates = [...privilegeStates];
        let privilege = newPrivilegeStates.find((p) => p.id === index);
        privilege.isActive = !privilege.isActive;
        setPrivilegeStates(newPrivilegeStates);
    };

    const handleValue = ({ type, value, index }) => {
        let forms = form.filter((f) => f.privilegeId !== index);
        let newForm = form.find((f) => f.privilegeId === index) || { privilegeId: index };
        newForm[type] = value;
        forms.push(newForm);
        setForm(forms);
        const formdata = forms.filter(form => form.validity !== 0);
        formik.setFieldValue("demandePrivilegeDTOS", formdata);
    };

    const handleAccessTypeChange = (event, index) => {
        const value = event.target.value;
        handleValue({ type: "accesType", value, index });
    };

    const handleValidityChange = (event, index) => {
        const value = event.target.value;
        handleValue({ type: "validity", value: parseInt(value), index });
    };
    console.log(values);
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Type d'accès</Popover.Header>
            <Popover.Body>
                <p>Accès API : l'accès API vous donnes accès à la base de données de l'ONI via API</p>
                <p>Accès Plateforme : l'accès Plateforme vous donnes accès à la base de données de l'ONI via la plateforme de l'ONI</p>
            </Popover.Body>
        </Popover>
    );

    return (
        <div>
            {loading ? (
                <div className='text-center'>
                    <Spinner size="sm" className="flex-shrink-0">En cours...</Spinner>
                </div>
            ) : (
                <div className="row gx-lg-5">
                    <div className="col-12"></div>
                    <div className="col-xl-12 col-md-6 mx-auto">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className='mx-4'>
                                        <FormGroup>
                                            <Label htmlFor="categorieSelect">
                                                Choisir la catégorie de votre structure
                                                <span className='text-danger'> *</span>
                                            </Label>
                                            <Form.Select required value={formik.values.categorieId || ''} onChange={onSelect}>
                                                <option value="">Choisir la catégorie de votre structure</option>
                                                {categories.map((data, key) => (
                                                    <option key={key} value={data.id}>{data.name} ({data.description})</option>
                                                ))}
                                            </Form.Select>
                                            {formik.errors.categorieId && <div className="text-danger">{formik.errors.categorieId}</div>}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="col-12 my-3">
                                    <div className='row prive mx-4'>
                                        {formik.values.categorieId && (
                                            <Label htmlFor="privilegesSelect">
                                                Choisir vos privilèges souhaités
                                                <span className='text-danger'> *</span>
                                            </Label>
                                        )}
                                        {privileges.map((data, key) => {
                                            const isCategorieD = categories.find(category => category.id === formik.values.categorieId)?.code === 'D';
                                            const categorieD = categories.filter(category => category.id === formik.values.categorieId);
                                            return (
                                                <React.Fragment key={key}>
                                                    <div className="form-check form-check-warning px-3 d-flex py-2">
                                                        <div>
                                                            <Label className="form-check-label">
                                                                {data.label}
                                                            </Label>
                                                            <p>{data.description}</p>
                                                        </div>
                                                        <div className='privechec'>
                                                            <FormGroup switch className="form-check form-switch form-switch-success">
                                                                <Input type="switch" className="form-check form-switch form-switch-success" role="switch" onChange={() => handlePrivilegeChange(data.value)}
                                                                    checked={isCategorieD ? true : privilegeStates.find((p) => p.id === data.value)?.isActive}
                                                                />
                                                            </FormGroup>
                                                            {isCategorieD ? (
                                                                <span className='text-danger'>Tous les privilèges font {categorieD[0]?.fraisExceptionnel} F CFA</span>
                                                            ) : (
                                                                <span className='red-text'>Prix : {data.prix * (form.find((f) => f.privilegeId === data.value)?.validity || 1)} F CFA</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {privilegeStates.find((p) => p.id === data.value)?.isActive && (
                                                        <Row className='py-3'>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label htmlFor={`validity${data.value}`}>
                                                                        Durée de validité en mois
                                                                        <span className='text-danger'> *</span>
                                                                    </Label>
                                                                    <select className="form-select mb-3" aria-label="Default select example"
                                                                        id={`validity${data.value}`}
                                                                        value={form.find((f) => f.privilegeId === data.value)?.validity || ''}
                                                                        onChange={(e) => handleValidityChange(e, data.value)}>
                                                                        <option value=''>Choisir la durée de validité en mois</option>
                                                                        {[...Array(24).keys()].map(i => (
                                                                            <option key={i} value={i + 1}>{i + 1}</option>
                                                                        ))}
                                                                    </select>
                                                                    {formik.errors.validity && <div className="text-danger">{formik.errors.validity}</div>}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label htmlFor={`accesType${data.value}`} className='typeacc'>
                                                                        Type d'accès
                                                                        <span className='text-danger'> *</span>
                                                                        <OverlayTrigger trigger="click" placement="top" overlay={popover} >
                                                                            <i className='ri-information-line mx-2'></i>
                                                                        </OverlayTrigger>
                                                                    </Label>
                                                                    <select className="form-select mb-3" aria-label="Default select example"
                                                                        id={`accesType${data.value}`}
                                                                        value={form.find((f) => f.privilegeId === data.value)?.accesType || ''}
                                                                        onChange={(e) => handleAccessTypeChange(e, data.value)}>
                                                                        <option value=''>Type d'accès souhaité</option>
                                                                        <option value="ACCES_API">Accès API</option>
                                                                        <option value="ACCES_PLATEFORME">Accès Application</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    {isCategorieD && !privilegeStates.find((p) => p.id === data.value)?.isActive && (
                                                        <Row className='py-3'>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label htmlFor={`validity${data.value}`}>
                                                                        Durée de validité en mois
                                                                        <span className='text-danger'> *</span>
                                                                    </Label>
                                                                    <select className="form-select mb-3" aria-label="Default select example"
                                                                        id={`validity${data.value}`}
                                                                        value={form.find((f) => f.privilegeId === data.value)?.validity || ''}
                                                                        onChange={(e) => handleValidityChange(e, data.value)}>
                                                                        <option value={0}>Choisir la durée de validité en mois</option>
                                                                        {[...Array(24).keys()].map(i => (
                                                                            <option key={i} value={i + 1}>{i + 1}</option>
                                                                        ))}
                                                                    </select>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label htmlFor={`accesType${data.value}`} className='typeacc'>
                                                                        Type d'accès
                                                                        <span className='text-danger'> *</span>
                                                                        <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                                                                            <i className='ri-information-line mx-2'></i>
                                                                        </OverlayTrigger>
                                                                    </Label>
                                                                    <select className="form-select mb-3" aria-label="Default select example"
                                                                        id={`accesType${data.value}`}
                                                                        value={form.find((f) => f.privilegeId === data.value)?.accesType || ''}
                                                                        onChange={(e) => handleAccessTypeChange(e, data.value)}>
                                                                        <option value=''>Type d'accès</option>
                                                                        <option value="ACCES_API">Accès API</option>
                                                                        <option value="ACCES_PLATEFORME">Accès Application</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                    {formik.errors.demandePrivilegeDTOS && <div className="text-center text-danger">{formik.errors.demandePrivilegeDTOS}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Accreditation;
