import React, { useEffect, useState } from "react"
import { getUserData, getUserRole } from "../../utility/Utils"
import { Dropdown } from "react-bootstrap"
import AuthService from "../../services/authService"
import logo from '../../assets/images/oni-logo1.jpeg'
import constant from "../../utility/constant"

const Header = () => {
    const appConstant = { ...constant };
    const userrole = { ...appConstant?.appRole };
        const role = getUserRole()
    const user = getUserData()

    const [userProfile, setUserProfile] = useState({})
    const [url, setUrl] = useState({})
  
        const logout=()=> {
            AuthService.logout(user?.user?.preferred_username);
        }
        useEffect(() => {
            setUrl(window.location.pathname.replace('/admin/', ''))
            if (getUserData()) {
                setUserProfile(getUserData().user);
            }
        }, [])

    return (
        <header id={(role==userrole?.manager || role === userrole?.cil) ?("page-topbar"):("page-topbar-"+userrole?.sousUser)}>
            <div className="layout-width">
                <div className="navbar-header">
                    <div className="d-flex">
                    </div>

                    <div className="d-flex align-items-center">

                        <div className="dropdown d-md-none topbar-head-dropdown header-item">
                            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i className="bx bx-search fs-22"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                aria-labelledby="page-header-search-dropdown">
                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search ..."
                                                aria-label="Recipient's username" />
                                            <button className="btn btn-primary" type="submit"><i
                                                className="mdi mdi-magnify"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                       

                        <div className="dropdown ms-sm-3 header-item topbar-user">
                            <Dropdown>
                                <Dropdown.Toggle variant="default" id="dropdown-basic">
                                <span className="d-flex align-items-center">
                                   
                                        <img className="rounded-circle header-profile-user" src={logo}
                                        alt="" />
                                          <span className="text-start ms-xl-2">
                                        {role==userrole?.manager?(

                                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{user?.user?.preferred_username}</span>
                                        ):(

                                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{user?.user?.preferred_username}</span>
                                        )}
                                        </span>
                                </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="/compte/mot-de-passe">Profil</Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={()=>logout()}>Deconnexion</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                           
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header